import React, {useState, useEffect} from 'react';
import { Image, Offcanvas} from 'react-bootstrap';
import EstateImage from "../../assets/img/atlantic.png";
import configData from "../../../config.json";
import { useHistory } from "react-router-dom";

const Atlantic = (props) => {
    const history = useHistory();
    const {estate} = props;
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [data, setData] = useState([]);
    const [properties, setProperties] = useState([]);
  
    const offCanvasClose = () => setShowOffcanvas(false);
    const offCanvasShow = () => setShowOffcanvas(true);

    const getEstPlots = async() => {

        try {

            return fetch(`${configData.TEST_URL}/plot/estate/plots/${estate._id}`, {
                method: "get",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  // "x-auth-token":  window.localStorage.getItem("token")
                },
              })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log(responseJson.data);
                    setProperties(responseJson.data);
                })
                .catch((error) => {
                  console.error(error);
                });
        
            } catch (error) {
                 console.log(error);
            }
    
    }
    
    useEffect(()=> {
        getEstPlots()
    },[])

    properties.forEach((property) => {
    const element = document.getElementById(`plot-${property.plotNumber}`);
    if(element) {
        if (property.status === 'sold') {
            element.style.fill = 'rgb(255, 0, 0, 0.4)';
            } 
        if (property.status === 'reserved') {
            element.style.fill = 'rgb(255, 210, 0, 0.4)';
            } 
        // else {
        //     element.style.fill = 'rgba(255, 255, 255, 0.2)';
        //   }
    }

    })

    const getPlotData = (id) => {
        alert(id);
        
       const selectedPlot = properties.find((property) => `plot-${property.plotNumber}` === id)
       if(selectedPlot) {
          setData(selectedPlot)
          offCanvasShow()
          console.log(selectedPlot)
       }
    }

    const svgPaths = [

{ id:"atvm-19", d:"M926 554.5L844.5 560.5L848.5 649.5L859 659L934 650L926 554.5Z" },
{ id:"atvm-01", d:"M1634.5 602.5L1572.5 609.626L1563.5 619.806L1566.5 715.5L1645.5 706.847L1634.5 602.5Z" },
{ id:"atvh-240", d:"M987 1728.5L895.5 1732.5L897.5 1788.5L989.5 1784.5L987 1728.5Z" },
{ id:"atvh-239", d:"M989.5 1786.5L897.5 1790.5L899.5 1827.5L907.5 1836.5L993 1857L989.5 1786.5Z" },
{ id:"atvh-242", d:"M981.5 1611.5L890.5 1615L892.5 1671.5L984.5 1667.5L981.5 1611.5Z" },
{ id:"atvh-241", d:"M984.5 1669.5L892.5 1673.5L894.5 1730.5L986.5 1726.5L984.5 1669.5Z" },
{ id:"atvh-218", d:"M493.5 1445.5H479.5L484.5 1565.5L533.5 1563.5L529.5 1459.5L499 1460L493.5 1445.5Z" },
{ id:"atvh-225", d:"M844 1552.5L794.5 1554.5L798.5 1659.5L840 1657.5L848 1649L844 1552.5Z" },
{ id:"atvh-226", d:"M791.5 1554.5L742.5 1556.5L746.5 1661.5L796.5 1659.5L791.5 1554.5Z" },
{ id:"atvh-227", d:"M740.5 1557.5L690.5 1559.5L694.5 1663.5L744.5 1661.5L740.5 1557.5Z" },
{ id:"atvh-228", d:"M688.5 1559.5L638.5 1561.5L643.5 1665.5L692.5 1663.5L688.5 1559.5Z" },
{ id:"atvh-229", d:"M636.5 1561.5L587.5 1563.5L591.5 1668.5L641.5 1666.5L636.5 1561.5Z" },
{ id:"atvh-230", d:"M585.5 1564L535.5 1565.5L540.5 1670.5L589.5 1668.5L585.5 1564Z" },
{ id:"atvh-231", d:"M533.5 1565.5L484.5 1567.5L489.5 1687.5H503L508.5 1671.5L537.5 1670.5L533.5 1565.5Z" },
{ id:"atvh-238", d:"M841.5 1690.5L800.5 1692.5H799.5L804.5 1796.5L854.5 1794.5L850.5 1699L841.5 1690.5Z" },
{ id:"atvh-237", d:"M797.5 1692.5L748.5 1694.5L752.5 1799.5L802.5 1797.5L797.5 1692.5Z" },
{ id:"atvh-236", d:"M746.5 1694.5L696.5 1696.5L700.5 1801.5L750.5 1799.5L746.5 1694.5Z" },
{ id:"atvh-235", d:"M694.5 1696.5L644.5 1699.5L649.5 1803.5L698.5 1801.5L694.5 1696.5Z" },
{ id:"atvh-234", d:"M642.5 1699.5L593.5 1701.5L597.5 1805.5L647.5 1803.5L642.5 1699.5Z" },
{ id:"atvh-233", d:"M591.5 1701.5L541.5 1703.5L545.5 1808.5L595.5 1806.5L591.5 1701.5Z" },
{ id:"atvh-232", d:"M503.5 1689.5H489.5V1690.5L494.5 1810.5L543.5 1808L539.5 1704L509.5 1704.5L503.5 1689.5Z" },
{ id:"atvh-255", d:"M1502.5 1364.5L1416.5 1368.5L1419.5 1443.5L1461.5 1442.5L1507.5 1377L1502.5 1364.5Z" },
{ id:"atvh-254", d:"M1414.5 1368.5L1345.5 1371.5L1348.5 1447L1417.5 1444L1414.5 1368.5Z" },
{ id:"atvm-05", d:"M1461.5 1444L1349.5 1448.5L1354 1566.5L1369.5 1577L1461.5 1444Z" },
{ id:"atvm-06", d:"M1348.5 1478L1288.5 1480.5L1294.5 1612.5L1339 1610.5L1351 1603.5L1368 1579L1352 1567.5L1348.5 1478Z" },
{ id:"atvm-07", d:"M1286.5 1480.5L1226.5 1483.5L1232.5 1615.5L1292.5 1612.5L1286.5 1480.5Z" },
{ id:"atvm-08", d:"M1224.5 1483.5L1164.5 1485.5L1170.5 1617.5L1230.5 1615.5L1224.5 1483.5Z" },
{ id:"atvm-09", d:"M1162.5 1486L1102.5 1488.5L1108.5 1620.5L1168.5 1618.5L1162.5 1486Z" },
{ id:"atvm-10", d:"M1100.5 1488.5L1040.5 1491.5L1046.5 1623.5L1106.5 1620.5L1100.5 1488.5Z" },
{ id:"atvm-11", d:"M1038.5 1491.5L979.5 1493.5L984.5 1625.5L1044.5 1623.5L1038.5 1491.5Z" },
{ id:"atvh-244", d:"M977.5 1493.5L885.5 1497.5L887.5 1554.5L979.5 1550.5L977.5 1493.5Z" },
{ id:"atvh-243", d:"M979.5 1552.5L887.5 1556.5L889.5 1613.5L981.5 1609.5L979.5 1552.5Z" },
{ id:"atvm-15", d:"M1315.5 1644.5L1206.5 1649.5L1210.5 1754.5L1247.5 1753.5L1319 1651L1315.5 1644.5Z" },
{ id:"atvm-14", d:"M1204.5 1649.5L1129.5 1652.5V1653L1133.5 1758.5L1208.5 1755L1204.5 1649.5Z" },
{ id:"atvm-13", d:"M1127.5 1652.5L1052.5 1655.5L1056.5 1761.5L1131.5 1758.5L1127.5 1652.5Z" },
{ id:"atvm-12", d:"M1020.5 1626.5C1020.5 1626.9 996.5 1627.33 984.5 1627.5L990.5 1764.5L1054.5 1761.5L1050.5 1656L1028.5 1657L1021.5 1650C1021.17 1642 1020.5 1626.1 1020.5 1626.5Z" },
{ id:"atvm-16", d:"M1246.5 1755.5L1136.5 1760.5L1142.5 1892.5L1152.5 1891.5L1246.5 1755.5Z" },
{ id:"atvm-17", d:"M1134 1761L1070 1763.5L1075 1877L1140 1893L1134 1761Z" },
{ id:"atvm-18", d:"M1068 1763L990 1767L994 1857.5L1073 1876.5L1068 1763Z" },
{ id:"atvh-253", d:"M1343.5 1371.5L1294.5 1373.5L1298.5 1478.5L1348.5 1476.5L1343.5 1371.5Z" },
{ id:"atvh-252", d:"M1292.5 1373.5L1242.5 1376L1246.5 1481L1297 1478.5L1292.5 1373.5Z" },
{ id:"atvh-224", d:"M831.5 1446.5L789.5 1448L793.5 1552.5L843.5 1550.5L839.5 1454.5L831.5 1446.5Z" },
{ id:"atvh-223", d:"M787.5 1448.5L737.5 1450.5L742.5 1554.5L791.5 1552.5L787.5 1448.5Z" },
{ id:"atvh-222", d:"M735.5 1450.5L686.5 1452.5L690.5 1557L740.5 1555.5L735.5 1450.5Z" },
{ id:"atvh-221", d:"M683.5 1452.5L634.5 1454.5L638.5 1559.5L688.5 1557.5L683.5 1452.5Z" },
{ id:"atvh-220", d:"M632.5 1454.5L582.5 1456.5L587.5 1561.5L636.5 1559.5L632.5 1454.5Z" },
{ id:"atvh-219", d:"M580.5 1457.5L531.5 1459.5L535.5 1563.5L585 1561.5L580.5 1457.5Z" },
{ id:"atvh-213", d:"M729.5 1312.5L680.5 1314.5L684.5 1419.5L734.5 1417.5L729.5 1312.5Z" },
{ id:"atvh-214", d:"M678.5 1315L628.5 1317.5L632.5 1421.5L682.5 1419.5L678.5 1315Z" },
{ id:"atvh-215", d:"M626.5 1317.5L576.5 1319.5L581.5 1423.5L630.5 1421.5L626.5 1317.5Z" },
{ id:"atvh-216", d:"M574.5 1319.5L525.5 1321.5L529.5 1426.5L579.5 1424.5L574.5 1319.5Z" },
{ id:"atvh-217", d:"M523.5 1321.5L474.5 1323.5L479 1443.5H492L497.5 1427.5L527.5 1426.5L523.5 1321.5Z" },
{ id:"atvh-206", d:"M621.5 1210.5L572.5 1212.5L576.5 1317.5L626.5 1315.5L621.5 1210.5Z" },
{ id:"atvh-205", d:"M570.5 1212.5L520.5 1214.5L524.5 1319.5L574.5 1317.5L570.5 1212.5Z" },
{ id:"atvh-204", d:"M482.5 1201.5H468.5L473.5 1321.5L522.5 1319.5L518.5 1215.5L489 1216.5L482.5 1201.5Z" },
{ id:"atvh-211", d:"M833.5 1308.5L783.5 1310.5L787.5 1415L829.5 1413.5L837.5 1405.5L833.5 1308.5Z" },
{ id:"atvh-212", d:"M781.5 1310.5L731.5 1312.5L736.5 1417.5L785.5 1415.5L781.5 1310.5Z" },
{ id:"atvh-210", d:"M778.5 1203.5L778.744 1203.49M778.744 1203.49L821.5 1202.5L829.5 1210.5L833.5 1306.5L783.5 1308.5L778.5 1204L778.744 1203.49Z" },
{ id:"atvh-209", d:"M776.5 1203L727.5 1205L731.5 1310L781.5 1308L776.5 1203Z" },
{ id:"atvh-208", d:"M725.5 1206.5L675.5 1208.5L680.5 1312.5L729.5 1310.5L725.5 1206.5Z" },
{ id:"atvh-207", d:"M673.5 1208.5L623.5 1210.5L628.5 1315.5L677.5 1313.5L673.5 1208.5Z" },
{ id:"atvh-176", d:"M1258.65 1239.5L1209.5 1241.52L1213.35 1344.5L1262.5 1342.48L1258.65 1239.5Z" },
{ id:"atvh-177", d:"M1206.5 1242L1155.5 1244.5L1159.5 1346.5L1210.5 1344L1206.5 1242Z" },
{ id:"atvh-178", d:"M1153.5 1244.5L1102.5 1246.5V1247L1106.5 1348.5L1157.5 1346.5L1153.5 1244.5Z" },
{ id:"atvh-179", d:"M1100.5 1246.5L1049.5 1248.5L1053.5 1350.5L1104.5 1348.5L1100.5 1246.5Z" },
{ id:"atvh-180", d:"M1047.5 1248.5L996.5 1250.5L1000.5 1352.5L1051.5 1350.5L1047.5 1248.5Z" },
{ id:"atvh-181", d:"M994.5 1251.5L943.5 1253.5L947.5 1355L998.5 1353L994.5 1251.5Z" },
{ id:"atvh-183", d:"M939.5 1197.5L872.5 1200L875.5 1277.5L942.5 1275.5L939.5 1197.5Z" },
{ id:"atvh-182", d:"M942.5 1277.5L875.5 1279.5V1280L878.5 1349.5L887 1357.5L946 1355.5L942.5 1277.5Z" },
{ id:"atvh-173", d:"M1418.5 1232.5L1367.5 1234.5L1371.5 1337.5L1422.5 1335.5L1418.5 1232.5Z" },
{ id:"atvh-174", d:"M1365.5 1235.5L1314.5 1237.5L1318.5 1339.5L1369.5 1337.5L1365.5 1235.5Z" },
{ id:"atvh-175", d:"M1312.5 1237.5L1261.5 1239.5L1265.5 1341.5L1316.5 1339.5L1312.5 1237.5Z" },
{ id:"atvh-160", d:"M990.5 1146.5L939.5 1148.5L943.5 1251.5L994.5 1249L990.5 1146.5Z" },
{ id:"atvh-171", d:"M1540.5 1251L1474.5 1253.5L1477.5 1332.5L1535 1330.5L1543.5 1321.5L1540.5 1251Z" },
{ id:"atvh-172", d:"M1471.5 1230.5L1420.5 1232.5L1424.5 1334.5L1475.5 1332.5L1471.5 1230.5Z" },
{ id:"atvh-170", d:"M1538.5 1171L1470.5 1173.5L1473.5 1251.5L1540.5 1248.5L1538.5 1171Z" },
{ id:"atvh-169", d:"M1466.5 1126.5L1415.5 1128.5L1420.5 1230.5L1470.5 1228.5L1466.5 1126.5Z" },
{ id:"atvh-168", d:"M1413.5 1128.5L1362.5 1130.5L1367.5 1233L1418.5 1231L1413.5 1128.5Z" },
{ id:"atvh-167", d:"M1360 1131.5L1309.5 1133.5L1314.5 1234.5L1365.5 1232.5L1360 1131.5Z" },
{ id:"atvh-166", d:"M1307.5 1133.5L1256.5 1135.5L1261.5 1237.5L1312 1235.5L1307.5 1133.5Z" },
{ id:"atvh-165", d:"M1204.5 1137.5L1204.85 1137.49M1204.85 1137.49L1254.5 1135.5L1259 1237.5L1208.5 1239.5L1204.85 1137.49Z" },
{ id:"atvh-164", d:"M1201.5 1137.5L1151.5 1140L1155.5 1241.5L1206 1239L1201.5 1137.5Z" },
{ id:"atvh-163", d:"M1149.5 1140.5L1098.5 1142.5L1102.5 1244.5L1153 1242.5L1149.5 1140.5Z" },
{ id:"atvh-162", d:"M1096.5 1142.5L1045.5 1144.5L1049.5 1246.5L1100.5 1244.5L1096.5 1142.5Z" },
{ id:"atvh-161", d:"M1043.5 1144.5L992.5 1146.5L996.5 1248.5L1047.5 1246.5L1043.5 1144.5Z" },
{ id:"atvh-139", d:"M1291.5 822.5L1195.5 826.5L1197.5 880.5L1294.5 876.5L1291.5 822.5Z" },
{ id:"atvh-148", d:"M1193.5 826.5L1096.5 830.5L1099 884.5L1195.5 880.5L1193.5 826.5Z" },
{ id:"atvh-140", d:"M1294.5 878.5L1197.5 882.5L1200.5 936.5L1296.5 932.5L1294.5 878.5Z" },
{ id:"atvh-147", d:"M1195.5 882.5L1099.5 886.5L1101.5 940.5L1198.5 936.5L1195.5 882.5Z" },
{ id:"atvh-141", d:"M1296.5 934.5L1200.5 938.5L1202.5 992.5L1298.5 988.5L1296.5 934.5Z" },
{ id:"atvh-146", d:"M1199 938.5L1101.5 942.5L1103.5 996.5L1200.5 992.5L1199 938.5Z" },
{ id:"atvh-142", d:"M1299.5 990.5L1202.5 994.5L1204.5 1048.5L1301.5 1044.5L1299.5 990.5Z" },
{ id:"atvh-143", d:"M1301.5 1046.5L1205.5 1050.5L1207.5 1104.5L1295.5 1100.5L1303.5 1092.5L1301.5 1046.5Z" },
{ id:"atvh-145", d:"M1200.5 994.5L1104.5 998.5L1106.5 1052.5L1202.5 1048.5L1200.5 994.5Z" },
{ id:"atvh-144", d:"M1202.5 1050.5L1106.5 1054.5L1108.5 1100L1117.5 1108.5L1205.5 1104.5L1202.5 1050.5Z" },
{ id:"atvm-03", d:"M1209.5 758.5L1068.5 774.5L1071.5 801L1096.5 807.5V828.5L1214.5 823.5L1209.5 758.5Z" },
{ id:"atvm-04", d:"M1066.5 774.5L944.5 788.5L949.5 851.5L1064.5 846.5L1063 809L1069.5 802L1066.5 774.5Z" },
{ id:"atvm-02", d:"M1523.5 722L1396.5 736.5L1399.5 795.5L1526 790.5L1523.5 722Z" },
{ id:"atvh-137", d:"M1394.5 736.5L1291 748.5L1292.5 791.5L1317 790.5L1324 798.5L1397.5 795.5L1394.5 736.5Z" },
{ id:"atvh-138", d:"M1288.5 749.5L1211.5 758.5L1216.5 823.5L1291.5 820.5L1288.5 749.5Z" },
{ id:"atvh-99", d:"M1255.5 645.5L1206.5 651.5L1218.5 755L1267.5 749.5L1255.5 645.5Z" },
{ id:"atvh-98", d:"M1205 651.5L1155.5 657.5L1166.5 761L1216.5 756L1205 651.5Z" },
{ id:"atvh-97", d:"M1153 657.5L1103.5 663.5L1115.5 767L1165 761.5L1153 657.5Z" },
{ id:"atvh-96", d:"M1102 663.5L1052.5 669.5L1065 772.5L1113.5 767L1102 663.5Z" },
{ id:"atvh-95", d:"M1051 669.5L1001.5 674.5L1013.5 778.5L1062.5 773L1051 669.5Z" },
{ id:"atvh-94", d:"M999.5 675.5L949.5 681L961.5 784.5L1010.5 778.5L999.5 675.5Z" },
{ id:"atvh-159", d:"M942.5 789.5L855.5 799.5L857.5 855L947.5 851.5L942.5 789.5Z" },
{ id:"atvh-126", d:"M1526 792.5L1425.5 796.5L1427.5 848.5L1527.5 844.5L1526 792.5Z" },
{ id:"atvh-136", d:"M1423.5 796.5L1324.5 800.5L1326.5 852.5L1425.5 848.5L1423.5 796.5Z" },
{ id:"atvh-127", d:"M1527.5 846.5L1427.5 850.5L1429.5 902.5L1529.5 898.5L1527.5 846.5Z" },
{ id:"atvh-135", d:"M1425.5 850.5L1326.5 854.5L1328.5 907.5L1427.5 903.5L1425.5 850.5Z" },
{ id:"atvh-128", d:"M1529.5 901L1430.5 904.5L1431.5 957.5L1530.5 953.5L1529.5 901Z" },
{ id:"atvh-134", d:"M1427.5 905L1328.5 909.5L1330.5 961.5L1429.5 957.5L1427.5 905Z" },
{ id:"atvh-129", d:"M1531 955.5L1432.5 959.5L1433.5 1011.5L1532.5 1007.5L1531 955.5Z" },
{ id:"atvh-133", d:"M1430 959.5L1331.5 963.5L1333.5 1016.5L1431.5 1012.5L1430 959.5Z" },
{ id:"atvh-130", d:"M1532.5 1010L1467.5 1012.5L1470 1093.5L1528 1090.5L1535.5 1082.5L1532.5 1010Z" },
{ id:"atvh-131", d:"M1465.5 1013L1400.5 1015.5L1403.5 1095.5L1468.5 1093.5L1465.5 1013Z" },
{ id:"atvh-149", d:"M1064.5 848.5L962.5 852.5L964.5 903.5L1066.5 898.5L1064.5 848.5Z" },
{ id:"atvh-158", d:"M960.5 852.5L857.5 856.5L859.5 907.5L962.5 903.5L960.5 852.5Z" },
{ id:"atvh-150", d:"M1067 900.5L964.5 905.5L966.5 956L1069.5 951.5L1067 900.5Z" },
{ id:"atvh-157", d:"M962.5 905.5L859.5 909.5L861.5 960.5L964.5 956.5L962.5 905.5Z" },
{ id:"atvh-151", d:"M1069.5 953.5L966.5 958.5L968.5 1008.5L1071.5 1004.5L1069.5 953.5Z" },
{ id:"atvh-156", d:"M964.5 958.5L862.5 962.5L864.5 1013.5L966.5 1008.5L964.5 958.5Z" },
{ id:"atvh-152", d:"M1071 1006.5L969.5 1010.5L970.5 1061.5L1074 1058L1071 1006.5Z" },
{ id:"atvh-155", d:"M966.5 1010.5L864.5 1015.5L866.5 1065.5L968.5 1061.5L966.5 1010.5Z" },
{ id:"atvh-153", d:"M1073.5 1059.5L971.5 1063.5L973.5 1114.5L1067.5 1110.5L1075.5 1102.5L1073.5 1059.5Z" },
{ id:"atvh-154", d:"M969.5 1063.5L866.5 1068L868.5 1111L876.5 1118.5L971.5 1114.5L969.5 1063.5Z" },
{ id:"atvh-132", d:"M1398.5 1015.5L1333.5 1018.5L1336.5 1090.5L1345 1098.5L1401.5 1096.5L1398.5 1015.5Z" },
{ id:"atvh-93", d:"M898.5 686.5L898.815 686.465M898.815 686.465L947.5 681L959.5 785L910.5 790.5L898.815 686.465Z" },
{ id:"atvh-92", d:"M860.5 691L851.5 701.5L854.5 797.5L908.5 790.5L896.5 687L860.5 691Z" },
{ id:"atvh-53", d:"M798.5 708.5L753.5 713.5L765.5 817L812.5 811L808.5 717.5L798.5 708.5Z" },
{ id:"atvh-52", d:"M752 713.5L702.5 718.5L714.5 822.5L763.5 817.5L752 713.5Z" },
{ id:"atvh-51", d:"M701 719.5L651.5 724.5L663.5 828.5L712.5 823L701 719.5Z" },
{ id:"atvh-50", d:"M650 725.5L600.5 730.5L612 834.5L661.5 829.5L650 725.5Z" },
{ id:"atvh-49", d:"M598.5 731.5L548.5 737L560.5 840.5L610.5 834.5L598.5 731.5Z" },
{ id:"atvh-48", d:"M546.5 737.5L497.5 742.5L509.5 846.5L558.5 840.5L546.5 737.5Z" },
{ id:"atvh-47", d:"M462.5 731.5H448.5L453.5 853.5L507.5 847L495.5 743L466.5 746.5L462.5 731.5Z" },
{ id:"atvh-184", d:"M785.5 817L737.5 822.5L741.5 927.5L790.5 925.5L785.5 817Z" },
{ id:"atvh-185", d:"M735.5 822.5L685.5 828.5L689.5 929.5L739.5 927.5L735.5 822.5Z" },
{ id:"atvh-186", d:"M683.5 828.5L631.5 834.5L634.5 932.5L687.5 930.5L683.5 828.5Z" },
{ id:"atvh-187", d:"M628.5 835L574.5 841.5L578.5 934.5L632.5 932.5L628.5 835Z" },
{ id:"atvh-188", d:"M572.5 842L515.5 848.5L519.5 937L576.5 934.5L572.5 842Z" },
{ id:"atvh-189", d:"M513.5 848.5L454.5 855.5L458.5 954.5H471L477.5 938.5L516.5 937.5L513.5 848.5Z" },
{ id:"atvh-196", d:"M811.5 958.5L768.5 959.5L773 1064.5L822.5 1062.5L818.5 965.5L811.5 958.5Z" },
{ id:"atvh-195", d:"M766.5 959.5L717.5 961.5L721.5 1066.5L770.5 1064.5L766.5 959.5Z" },
{ id:"atvh-194", d:"M714.5 961.5L665.5 964.5L669.5 1068.5L719 1066.5L714.5 961.5Z" },
{ id:"atvh-193", d:"M663.5 964.5L613.5 966.5L617.5 1070.5L667.5 1068.5L663.5 964.5Z" },
{ id:"atvh-192", d:"M611.5 966.5L561.5 968.5L566.5 1072.5L615.5 1070.5L611.5 966.5Z" },
{ id:"atvh-191", d:"M559.5 968.5L510.5 970.5L514.5 1075.5L564 1073L559.5 968.5Z" },
{ id:"atvh-190", d:"M472.5 957.5H458.5L463.5 1077L512.5 1075.5L508.5 971L478.5 972L472.5 957.5Z" },
{ id:"atvh-197", d:"M823.5 1064.5L773.5 1066.5L777.5 1170.5L818.5 1169.5L827.5 1160.5L823.5 1064.5Z" },
{ id:"atvh-198", d:"M771.5 1066.5L721.5 1068.5L725.5 1173L775.5 1170.5L771.5 1066.5Z" },
{ id:"atvh-199", d:"M719.5 1068.5L669.5 1070.5L674.5 1174.5L723.5 1173L719.5 1068.5Z" },
{ id:"atvh-200", d:"M667.5 1070.5L618.5 1072.5L622.5 1177.5L672 1175L667.5 1070.5Z" },
{ id:"atvh-201", d:"M616 1072.5L566.5 1075.5L570.5 1179.5L620.5 1177.5L616 1072.5Z" },
{ id:"atvh-202", d:"M564.5 1075.5L514.5 1077.5L519.5 1181.5L568.5 1179.5L564.5 1075.5Z" },
{ id:"atvh-203", d:"M512.5 1077.5L463.5 1079.5L469 1199H482L488 1183L517.5 1182L512.5 1077.5Z" },
{ id:"atvh-107", d:"M1788.5 586.5L1739.5 591.5L1750.5 694L1799.5 689L1788.5 586.5Z" },
{ id:"atvh-106", d:"M1737.5 591.5L1688.5 596.5L1699 699.5L1748.5 694.5L1737.5 591.5Z" },
{ id:"atvh-105", d:"M1686 597L1636.5 602.5L1647.5 705L1697 699.5L1686 597Z" },
{ id:"atvh-104", d:"M1510.5 616.5L1462.5 621.5L1474.5 725.5L1523 720L1519.5 625L1510.5 616.5Z" },
{ id:"atvh-103", d:"M1460.5 622L1411.5 627.5L1423.5 731.5L1472.5 725.5L1460.5 622Z" },
{ id:"atvh-102", d:"M1409.5 627.5L1360.5 633.5L1372.5 737.5L1421.5 732L1409.5 627.5Z" },
{ id:"atvh-101", d:"M1358.5 633.5L1308.5 639.5L1320.5 743.5L1369.5 737.5L1358.5 633.5Z" },
{ id:"atvh-100", d:"M1306.5 640L1257.5 645.5L1269.5 749L1318.5 744L1306.5 640Z" },
{ id:"atvh-109", d:"M1836.5 450L1787.5 453.5H1787L1791.5 555.5L1823 552.5L1830.5 565.5H1842L1836.5 450Z" },
{ id:"atvh-110", d:"M1785.5 453.5L1734.5 457.5L1739.5 561.5L1789.5 556.5L1785.5 453.5Z" },
{ id:"atvh-111", d:"M1732.5 457.5L1683.5 461.5L1687.5 566.5L1737.5 561.5L1732.5 457.5Z" },
{ id:"atvh-112", d:"M1681.5 461.5L1633.5 464.5L1637.5 572.5L1686 567.5L1681.5 461.5Z" },
{ id:"atvh-114", d:"M1629.5 430.5L1556.5 433.5L1559.5 504.5L1632.5 501.5L1629.5 430.5Z" },
{ id:"atvh-113", d:"M1632.5 503.5L1559.5 506.5L1561.5 570.5L1572 579.5L1635.5 572.5L1632.5 503.5Z" },
{ id:"atvh-108", d:"M1826 582L1790.5 586L1801.5 689L1847 684L1841.5 567.5H1830.5L1826 582Z" },
{ id:"atvh-115", d:"M1564 358.5L1554.5 368.5L1556.5 431.5L1629.5 428.5L1626.5 356L1564 358.5Z" },
{ id:"atvh-117", d:"M1728.5 351.5L1679.5 353.5H1679L1683.5 459.5L1732.5 455.5L1728.5 351.5Z" },
{ id:"atvh-116", d:"M1677 353.5L1628.5 355.5L1632.5 462.5L1681.5 459.5L1677 353.5Z" },
{ id:"atvh-84", d:"M1469.5 527.5L1387.5 530.5L1389.5 596.5L1471.5 587.5L1469.5 527.5Z" },
{ id:"atvh-85", d:"M1385.5 530.5L1309.5 533.5L1312.5 605.5L1387.5 597L1385.5 530.5Z" },
{ id:"atvh-86", d:"M1307.5 534.5L1237.5 536.5L1241.5 613.5L1310.5 605.5L1307.5 534.5Z" },
{ id:"atvh-87", d:"M1170.5 539.5C1170.1 540.3 1213.67 538.5 1235.5 537.5L1239 613.5L1173.5 621.5L1170.5 539.5Z" },
{ id:"atvh-88", d:"M1168.5 540L1106.5 542.5L1109.5 629.5L1171.5 621.5L1168.5 540Z" },
{ id:"atvh-89", d:"M1104.5 542.5L1044.5 545.5L1050.5 635.5L1107.5 629.5L1104.5 542.5Z" },
{ id:"atvh-90", d:"M1042.5 545.5L985.5 550.5L992.5 642.5L1048.5 636.5L1042.5 545.5Z" },
{ id:"atvh-91", d:"M983.5 550.5L927.5 554.5L935.5 649L989.5 642.5L983.5 550.5Z" },
{ id:"atvh-83", d:"M1514.5 474L1409.5 478L1410.5 527.5L1516.5 522.5L1514.5 474Z" },
{ id:"atvh-76", d:"M1406.5 427L1301.5 431.5L1303.5 481.5L1407.5 477L1406.5 427Z" },
{ id:"atvh-69", d:"M1153.5 262.5L1072.5 258.5L1064 266.5L1067.5 321L1155.5 317.5L1153.5 262.5Z" },
{ id:"atvh-71", d:"M1263.5 314.5L1166.5 318.5L1168.5 372.5L1265.5 368.5L1263.5 314.5Z" },
{ id:"atvh-68", d:"M1164.5 319L1067.5 323.5L1069.5 376.5L1166.5 372.5L1164.5 319Z" },
{ id:"atvh-72", d:"M1265.5 370.5L1168.5 374.5L1170.5 427.5L1268.5 423.5L1265.5 370.5Z" },
{ id:"atvh-67", d:"M1166.5 374.5L1069.5 378.5L1071.5 432.5L1168.5 428.5L1166.5 374.5Z" },
{ id:"atvh-73", d:"M1268.5 425.5L1171.5 430L1173.5 483.5L1271 479.5L1268.5 425.5Z" },
{ id:"atvh-66", d:"M1169.5 430.5L1071.5 434.5L1073.5 487.5L1171.5 484L1169.5 430.5Z" },
{ id:"atvh-74", d:"M1270.5 481.5L1173.5 485.5L1175.5 537.5L1288.5 532.5L1287.5 515L1271.5 508.5L1270.5 481.5Z" },
{ id:"atvh-75", d:"M1407.5 478.5L1304.5 483.5V507.5L1289.5 514.5L1290.5 532.5L1408.5 528L1407.5 478.5Z" },
{ id:"atvh-55", d:"M938.5 445L840.5 448.5L842.5 502.5L939.5 497.5L938.5 445Z" },
{ id:"atvh-64", d:"M1042.5 494.5L942.5 498.5L944.5 551L1059.5 542.5V525L1043.5 518.5L1042.5 494.5Z" },
{ id:"atvh-125", d:"M1498.5 279L1455.5 276.5L1459.5 371L1511 368.5L1508.5 289.5L1498.5 279Z" },
{ id:"atvh-80", d:"M1453.5 276.5L1399.5 274.5L1403.5 372.5L1457.5 370.5L1453.5 276.5Z" },
{ id:"atvh-79", d:"M1397.5 274L1302.5 269.5L1295.5 276.5L1296.5 324.5L1399 321L1397.5 274Z" },
{ id:"atvh-78", d:"M1399.5 322.5L1297.5 326.5L1299.5 377.5L1401.5 373.5L1399.5 322.5Z" },
{ id:"atvh-81", d:"M1511.5 371L1406.5 375.5L1408.5 424.5L1512.5 420.5L1511.5 371Z" },
{ id:"atvh-77", d:"M1404.5 375.5L1299.5 379.5L1301.5 429L1406.5 425L1404.5 375.5Z" },
{ id:"atvh-82", d:"M1512.5 422.5L1408.5 426.5L1409.5 476L1514.5 471.5L1512.5 422.5Z" },
{ id:"atvh-65", d:"M1171.5 485.5L1074.5 489.5L1075.5 516.5L1060.5 524.5V542L1173.5 537.5L1171.5 485.5Z" },
{ id:"atvh-54", d:"M939.5 499.5L842.5 504.5L844.5 558.5L941.5 551.5L939.5 499.5Z" },
{ id:"atvh-62", d:"M1037 385.5L938.5 389.5L940.5 442.5L1038.5 438L1037 385.5Z" },
{ id:"atvh-57", d:"M933.5 334.5L835.5 338.5V339L837.5 391.5L936.5 387.5L933.5 334.5Z" },
{ id:"atvh-56", d:"M936.5 389.5L837.5 393.5L840.5 446.5L938.5 442.5L936.5 389.5Z" },
{ id:"atvh-63", d:"M1038.5 440.5L940.5 444.5L941.5 496.5L1041.5 492.5L1038.5 440.5Z" },
{ id:"atvh-60", d:"M1021.5 256.5L960.5 253.5L963.5 331.5L1034.5 328.5L1031.5 267L1021.5 256.5Z" },
{ id:"atvh-59", d:"M958.5 253.5L894.5 250.5L897.5 334L961.5 331L958.5 253.5Z" },
{ id:"atvh-58", d:"M892.5 249.5L841.5 247.5L831.5 257L835.5 336.5L895.5 335L892.5 249.5Z" },
{ id:"atvh-61", d:"M1034.5 330.5L935.5 334.5L938.5 387.5L1036.5 383.5L1034.5 330.5Z" },
{ id:"atvh-46", d:"M492.593 606.5L444.5 611.5L449.407 729.5H462.657L464.62 713.5L497.5 709.5L492.593 606.5Z" },
{ id:"atvh-23", d:"M584.5 256.5L533.5 258.5L537.5 360.5L588.5 357.5L584.5 256.5Z" },
{ id:"atvh-31", d:"M588.5 359.5L537.5 362.5H537L541.5 464.5L592.5 460.5L588.5 359.5Z" },
{ id:"atvh-22", d:"M531.5 258.5L480.5 260.5L484.5 363.5L535.5 360.5L531.5 258.5Z" },
{ id:"atvh-32", d:"M535.5 362.5L484.5 365.5L489.5 468.5L539.5 464.5L535.5 362.5Z" },
{ id:"atvh-21", d:"M441.5 247.5H428.5L433 366.5L482.5 363.5L478.5 261.5L445.5 262.5L441.5 247.5Z" },
{ id:"atvh-33", d:"M482.5 366L433.5 368.5L438 487.5H451.5L453.5 471.5L487.5 468.5L482.5 366Z" },
{ id:"atvh-39", d:"M788.5 478.5L704.5 484.5L706.5 538.5L800.5 534.5L798.5 487.5L788.5 478.5Z" },
{ id:"atvh-40", d:"M800.5 537L706.5 540.5L708.5 595.5L803 592.5L800.5 537Z" },
{ id:"atvh-41", d:"M770.5 595.5L708.5 597.5L712.5 684.5L774.5 677.5L770.5 595.5Z" },
{ id:"atvh-38", d:"M701.5 484.5L650.5 488.5L654.5 588.5L706.5 583.5L701.5 484.5Z" },
{ id:"atvh-42", d:"M706.5 586L654.5 590.5L658.5 690.5L710.5 684.5L706.5 586Z" },
{ id:"atvh-37", d:"M647.5 489.5L596.5 493L600.5 594.5L652.5 589.5L647.5 489.5Z" },
{ id:"atvh-43", d:"M652.5 591.5L600.5 596.5L605 696.5L656.5 690.5L652.5 591.5Z" },
{ id:"atvh-36", d:"M594.5 493.5L543.5 497.5L547.5 599.5L599 594.5L594.5 493.5Z" },
{ id:"atvh-44", d:"M598.5 596.5L547.5 601.5L552.5 703L604 697.5L598.5 596.5Z" },
{ id:"atvh-35", d:"M541.5 497.5L490.5 501.5L495.5 604.5L545.5 599.5L541.5 497.5Z" },
{ id:"atvh-45", d:"M545.5 601.5L495.5 606.5L499.5 709.5L550 703.5L545.5 601.5Z" },
{ id:"atvh-34", d:"M451.5 489.5H438.5L443.5 610L492.5 604L488.5 502L457 504.5L451.5 489.5Z" },
{ id:"atvh-18", d:"M575.5 119.5L526.5 121.5H525.5L530.5 225.5L579.5 223.5L575.5 119.5Z" },
{ id:"atvh-19", d:"M523.5 122.5L474.5 124.5L478.5 227.5L529 225.5L523.5 122.5Z" },
{ id:"atvh-20", d:"M473 124.5L422.5 126.5L427.5 245.5H441.5L443.5 229.5L476.5 228.5L473 124.5Z" },
{ id:"atvh-26", d:"M756.5 248.5L694.5 251.5L697.5 334.5L759.5 333L756.5 248.5Z" },
{ id:"atvh-27", d:"M791.5 333.5L697.5 336.5L700.5 392.5L793.5 388.5L791.5 333.5Z" },
{ id:"atvh-25", d:"M692.5 252.5L640.5 253.5H639.5L644.5 353.5L696.5 350.5L692.5 252.5Z" },
{ id:"atvh-28", d:"M794.5 390.5L700.5 394.5L702.5 451.5L785.5 445.5L796.5 434.5L794.5 390.5Z" },
{ id:"atvh-29", d:"M697 353.5L644.5 355.5L648.5 455.5L700.5 451.5L697 353.5Z" },
{ id:"atvh-24", d:"M637.5 254L586.5 256.5L590.5 357L642 354.5L637.5 254Z" },
{ id:"atvh-30", d:"M642.5 356.5L590.5 359.5L595.5 460.5L646.5 457L642.5 356.5Z" },
{ id:"atvh-15", d:"M730.5 113.5L680.5 115.5L685.5 218.5L734.5 216.5L730.5 113.5Z" },
{ id:"atvh-16", d:"M678.5 115.5L629.5 117.5H629L633.5 221.5L682.5 219.5L678.5 115.5Z" },
{ id:"atvh-17", d:"M627.5 117.5L577.5 119.5L581.5 222.5L631 221.5L627.5 117.5Z" },
{ id:"atvh-14", d:"M782.5 111.5L732.5 113.5H732L736.5 216.5L776.5 215.5L786.5 204.5L782.5 111.5Z" },
{ id:"atvh-12", d:"M933.5 116.5L882.5 113.5L878.5 216.5L928.5 218.5L933.5 116.5Z" },
{ id:"atvh-13", d:"M881.5 113.5L825.5 111.5L825 112L829.5 203.5L840.5 214.5L876.5 215.5L881.5 113.5Z" },
{ id:"atvh-10", d:"M1037.5 121.5L987.5 119L982.5 221L1033 223.5L1037.5 121.5Z" },
{ id:"atvh-11", d:"M985 118.5L935 116.5L930.5 218.5L980.5 221L985 118.5Z" },
{ id:"atvh-09", d:"M1089.5 123.5L1039.5 121.5L1035 223.5L1085.5 225.5L1089.5 123.5Z" },
{ id:"atvh-08", d:"M1141.5 126L1092 124L1087.5 226.5L1138 228.5L1141.5 126Z" },
{ id:"atvh-07", d:"M1193.5 128.5L1144 126.5L1139.5 228L1189.5 230.5L1193.5 128.5Z" },
{ id:"atvh-06", d:"M1246 130.5L1196 129L1191.5 231L1241.5 233L1246 130.5Z" },
{ id:"atvh-04", d:"M1350 136L1300.5 133.5L1295.5 236L1345.5 238L1350 136Z" },
{ id:"atvh-05", d:"M1298 133.5L1248.5 131.5L1243 233L1293.5 235L1298 133.5Z" },
{ id:"atvh-121", d:"M1772.5 224.5L1718.5 226.5H1717.5L1722 322L1777 319.5L1772.5 224.5Z" },
{ id:"atvh-122", d:"M1717 227L1662 228.5L1666.5 324.5L1720.5 322.5L1717 227Z" },
{ id:"atvh-123", d:"M1660.5 228.5L1606.5 230.5H1606L1610.5 326.5L1664.5 324.5L1660.5 228.5Z" },
{ id:"atvh-124", d:"M1604.5 230.5L1550.5 232.5H1550L1552.5 319.5L1563 328.5L1607.5 326.5L1604.5 230.5Z" },
{ id:"atvh-120", d:"M1827.5 222.5L1774.5 224.5V225L1778.5 319.5L1814 318.5L1820.5 331.5H1832L1827.5 222.5Z" },
{ id:"atvh-119", d:"M1816 347.5L1783 348.5L1787 451.5L1836.5 447.5L1831.5 333.5H1821L1816 347.5Z" },
{ id:"atvh-118", d:"M1780.5 349.5L1730.5 351.5L1734.5 455.5L1784.5 451.5L1780.5 349.5Z" },
{ id:"atvh-01", d:"M1503.5 143L1456 141.5L1452.5 243.5L1497.5 245.5L1507 236.5L1503.5 143Z" },
{ id:"atvh-02", d:"M1454.5 141L1404 138.5L1402.5 186.5L1400.5 241.5L1450.5 243.5L1451.5 218.5L1453 179.5L1454.5 141Z" },
{ id:"atvh-03", d:"M1402.5 138.5L1352.5 136.5L1348 238L1397.5 240.5L1402.5 138.5Z" },
{ id:"atvh-70", d:"M1253 267L1155.5 262.5L1157.5 317.5L1263.5 312.5L1261.5 275.5L1253 267Z" },
{ id:"atvh-251", d:"M1240.5 1375.5L1190.5 1377.5L1195.5 1482.5L1244.5 1480.5L1240.5 1375.5Z" },
{ id:"atvh-250", d:"M1188.5 1378.5L1139.5 1380.5L1143.5 1484.5L1193 1482.5L1188.5 1378.5Z" },
{ id:"atvh-249", d:"M1137.5 1380.5L1087.5 1382.5L1091.5 1486.5L1141.5 1484.5L1137.5 1380.5Z" },
{ id:"atvh-248", d:"M1085.5 1382.5L1035.5 1384.5L1040.5 1489.5L1089.5 1487.5L1085.5 1382.5Z" },
{ id:"atvh-247", d:"M1033.5 1384.5L984.5 1386.5L988.5 1491.5L1038.5 1489.5L1033.5 1384.5Z" },
{ id:"atvh-246", d:"M982.5 1386.5L932.5 1388.5L936.5 1493.5L986.5 1491.5L982.5 1386.5Z" },
{ id:"atvh-245", d:"M930.5 1389.5L888.5 1390.5L880.5 1399.5L884.5 1495.5L934.5 1493.5L930.5 1389.5Z" },
// { id:"Vector 3462", d:"M1973 113L2052.5 84.1813M2052.5 84.1813L2053 84V1H1V2125H2052.5V84.1813Z" },
        
    ];

    const navigation = (data) => {
        history.push({
          pathname: `/Client-Details/${data?._id}`,
          state: { data: data },
        });
    }
  
    return (
        <>

       {/* <div className="wrapper">
         <div className="scrollable-content"> */}
                  <div  style={{background: `url(${EstateImage})`, backgroundSize:"cover", width:"100%", 
                  transform: "scale(2)", transformOrigin: "top left"
                  }}  className="layout">
                         
                  <svg width="2054" height="2126" viewBox="0 0 2054 2126" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group 2">
                        {svgPaths.map((path) => (
                            <path
                                key={path.id}
                                fill='rgba(255, 255, 255, 0.2)'
                                id={path.id}
                                d={path.d}
                                stroke="black"
                                onClick={() => getPlotData(path.id)}
                                />
                        ))}
                        </g>
                    
                </svg>

 
          </div>
        {/* </div> 

        </div> */}


                            <Offcanvas show={showOffcanvas} onHide={offCanvasClose} placement={'end'}>
                                  {/* <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>Edit Document</Offcanvas.Title>
                                  </Offcanvas.Header> */}
                    <Offcanvas.Body>

                            <div className="estateDetails">
                                <span>{estate.name}</span>
                                <span>{estate.location}</span>
                            </div>


                  <div className='userProfDisplay'>

                        <div className="profInfo">
                                    <h4>Property Details</h4>

                                    <div className="profInfoData">

                                        <div className="profData">
                                            <span>Name</span>
                                            <span>{estate.name}</span>
                                        </div>

                                        
                                        <div className="profData">
                                            <span>Plot Number</span>
                                            <span>{data.plotNumber}</span>
                                        </div>

                                        <div className="profData">
                                            <span>Sqm</span>
                                            <span>{data.size}m<sup>2</sup></span>
                                        </div>

                                        <div className="profData">
                                            <span>Status</span>
                                            <span className={data.status === 'sold' ? 'soldStatus' : (data.status === 'pending' ? 'pendingStatus' : 'availableStatus')}>{data.status === 'sold' ? 'sold' : (data.status === 'reserved' ? 'reserved' : 'available')}</span>
                                        </div>
                                    </div>
                                </div>

                        <div className="profInfo">
                            {data.docId ? <>

                                <h4>Owned By</h4>

                                <div className="user-details">

                                    <Image crossorigin="anonymous" src={`${configData.TEXT_IMG}/${data.docId.clientId.passport}`} className="useDataImg3" alt="" />
                                    <div className="userDataName">
                                        <span>{data.docId.clientId.fullName}</span>
                                        <span>{data.docId.clientId.phoneNumber}</span>
                                    </div>
                                </div>

                                <div className="profInfoData">

                                    <div className="profData">
                                        <span>Full Name</span>
                                        <span>{data.docId.clientId.fullName}</span>
                                    </div>

                                    <div className="profData">
                                        <span>Phone Number</span>
                                        <span>{data.docId.clientId.phoneNumber}</span>
                                    </div>
                                    
                                    <div className="profData">
                                        <span>Sex</span>
                                        <span>{data.docId.clientId.sex}</span>
                                    </div>
                                    
                                    <div className="profData">
                                        <span>Email</span>
                                        <span>{data.docId.clientId.email}</span>
                                    </div>

                                    <div className="view-more" onClick={() => navigation(data.docId.clientId)}>
                                        <span>view more</span>
                                    </div>

                                    
                                </div>

                                </> : <> 
                                <span>This property does not have an owner</span> 
                            </>}
                        </div>

                    </div> 

                                  </Offcanvas.Body>
                              </Offcanvas>





            
        </>
    );
};

export default Atlantic;