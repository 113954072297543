import React, {useState, useEffect} from 'react';
import { Image, Offcanvas} from 'react-bootstrap';
import ParadiseImage from "../../assets/img/paradise.png";
import configData from "../../../config.json";
import { useHistory } from "react-router-dom";

const Paradise = (props) => {
    const history = useHistory();
    const {estate} = props;
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [data, setData] = useState([]);
    const [properties, setProperties] = useState([])
    const [isZoom, setIsZoom] = useState(false);
  
    const offCanvasClose = () => setShowOffcanvas(false);
    const offCanvasShow = () => setShowOffcanvas(true);

    const getEstPlots = async() => {

        try {

            return fetch(`${configData.TEST_URL}/plot/estate/plots/${estate._id}`, {
                method: "get",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  // "x-auth-token":  window.localStorage.getItem("token")
                },
              })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log(responseJson.data);
                    setProperties(responseJson.data);
                })
                .catch((error) => {
                  console.error(error);
                });
        
            } catch (error) {
                 console.log(error);
            }
    
    }
    
    useEffect(()=> {
        getEstPlots()
    },[])

    properties.forEach((property) => {
    const element = document.getElementById(`plot-${property.plotNumber}`);
    if(element) {
        if (property.status === 'sold') {
            element.style.fill = 'rgb(255, 0, 0, 0.4)';
            } 
        if (property.status === 'reserved') {
            element.style.fill = 'rgb(255, 210, 0, 0.4)';
            } 
        // else {
        //     element.style.fill = 'rgba(255, 255, 255, 0.2)';
        //   }
    }

    })

    const getPlotData = (id) => {
        alert(id);
        
       const selectedPlot = properties.find((property) => `plot-${property.plotNumber}` === id)
       if(selectedPlot) {
          setData(selectedPlot)
          offCanvasShow()
          console.log(selectedPlot)
       }
    }

    const svgPaths = [
        { id:"epm-46", d:"M824.5 2578L820 2654L863.5 2676.5L887.5 2597.5L824.5 2578Z"},
        { id:"epm-47", d:"M889 2598.5L865.5 2677L910 2699L941 2614L889 2598.5Z"},
        { id:"epm-48", d:"M943 2613.5L912 2699.5L979 2698L987 2627.5L943 2613.5Z"},
        { id:"epm-49", d:"M989.5 2628.5L981.5 2698.5L1012 2705.5L1042.5 2707.5L1048 2633.5L989.5 2628.5Z"},
        { id:"epm-50", d:"M1050.5 2633.5L1044.5 2707L1081.5 2708.5L1132 2658.5L1117 2638.5L1050.5 2633.5Z"},
        { id:"epm-51", d:"M1133 2660L1083.5 2710L1119.5 2758L1157 2744.5L1148.5 2725L1168.5 2707.5L1133 2660Z"},
        { id:"epm-52", d:"M1157.5 2746.5L1119.5 2760V2824.5H1189.5V2772H1170L1157.5 2746.5Z"},
        { id:"epm-53", d:"M1189.5 2826.5H1119.5V2859L1113 2868.5L1184 2907L1189.5 2896V2826.5Z"},
        { id:"epm-54", d:"M1094 2893L1078.5 2906.5L1125 2959.5L1182.5 2908.5L1111.5 2870L1094 2893Z"},
        { id:"epm-55", d:"M1077.5 2907.5L1029 2949.5L1074.5 3002.5L1122.5 2960L1077.5 2907.5Z"},
        { id:"epm-56", d:"M1027 2950.5L978.5 2995.5L1025.5 3044.5L1072.5 3003L1027 2950.5Z"},
        { id:"epm-57", d:"M977.5 2997L928 3043.5L972.5 3090L1024 3047L977.5 2997Z"},
        { id:"epm-58", d:"M888 3088.5L905.5 3148.5L971 3092.5L926.5 3045.5L920 3052L910 3041.5L888 3088.5Z"},
        { id:"epm-59", d:"M1491.5 3060.5V3081.5L1587.5 3131.5L1570 3045L1491.5 3060.5Z"},
        { id:"epm-60", d:"M1558 2985L1489.5 2998.5L1491.5 3058.5L1571 3043L1558 2985Z"},
        { id:"epm-61", d:"M1555.5 2924.5L1488 2926L1489.5 2996.5L1559 2984L1557 2975L1555.5 2924.5Z"},
        { id:"epm-62", d:"M1554.5 2858.5H1485.5L1487.5 2923H1555.5L1554.5 2858.5Z"},
        { id:"epm-63", d:"M1485.5 2815V2856H1555L1554 2798L1557.5 2792L1523 2772.5L1498.5 2815H1485.5Z"},
        { id:"eph-336", d:"M1370.5 2871.5H1331.5V2946.5H1370.5V2871.5Z"},
        { id:"eph-335", d:"M1305.5 2863.5H1291.5V2946.5H1329.5V2870.5H1310L1305.5 2863.5Z"},
        { id:"eph-370", d:"M1289.5 2863.5H1222.5V2906.5H1289.5V2863.5Z"},
        { id:"eph-369", d:"M1222 2908.5L1214.5 2925L1266.5 2967.5L1289.5 2947.5V2908.5H1222Z"},
        { id:"eph-339", d:"M1455 2948.5H1392V2997.5L1456 2992.5L1455 2948.5Z"},
        { id:"eph-340", d:"M1457 2994.5L1401 2998.5L1404.5 3053L1451 3051.5L1458 3043.5L1457 2994.5Z"},
        { id:"eph-342", d:"M1390.5 2948.5H1323.5V2972.5L1336.5 2971L1342.5 3001.5L1390.5 2997.5V2948.5Z"},
        { id:"eph-341", d:"M1398.5 2999.5L1343 3003.5L1353.5 3059L1361.5 3063L1390.5 3053.5H1402.5L1398.5 2999.5Z"},
        { id:"eph-343", d:"M1291 2948.5L1257 2979L1280 3020L1318.5 2998L1314.5 2979L1321.5 2973V2948.5H1291Z"},
        { id:"eph-346", d:"M1255 2980.5L1194 3033.5L1216 3057L1278 3021.5L1255 2980.5Z"},
        { id:"eph-344", d:"M1318.5 3000.5L1270 3028.5L1301 3082.5L1326 3074.5L1331.5 3064L1318.5 3000.5Z"},
        { id:"eph-345", d:"M1267.5 3029.5L1217 3058L1248.5 3092.5L1262 3095.5L1298.5 3083L1267.5 3029.5Z"},
        { id:"eph-350", d:"M1116.5 3009.5L1070.5 3049.5L1099.5 3081.5L1151.5 3036L1127 3009.5H1116.5Z"},
        { id:"eph-349", d:"M1152.5 3038L1100.5 3083L1129.5 3114.5L1181.5 3069L1152.5 3038Z"},
        { id:"eph-348", d:"M1183 3070.5L1130.5 3116.59L1159.5 3148.5L1211.5 3101.9L1183 3070.5Z"},
        { id:"eph-351", d:"M1021.5 3092V3102.5L1045.5 3128.5L1097.5 3083L1068.5 3051.5L1021.5 3092Z"},
        { id:"eph-352", d:"M1099 3085L1047 3130L1075.5 3161.5L1127.5 3116L1099 3085Z"},
        { id:"eph-353", d:"M1129 3117L1076 3163L1104.5 3194.5L1157.5 3148.5L1129 3117Z"},
        { id:"eph-347", d:"M1213 3103.5L1152.5 3156L1178.5 3186L1219 3140.5L1241 3133.5L1213 3103.5Z"},
        { id:"eph-354", d:"M1150.5 3157.5L1106.5 3196L1123 3214.5V3221L1114.5 3229L1128.5 3242L1177 3187.5L1150.5 3157.5Z"},
        { id:"eph-358", d:"M992.5 3116.5L956.5 3148L987 3189L1033.5 3148L1004 3116.5H992.5Z"},
        { id:"eph-357", d:"M1034 3150.5L988 3190.5L1016 3228L1065.5 3185L1034 3150.5Z"},
        { id:"eph-356", d:"M1066.5 3186.5L1017 3229.5L1045.5 3264L1097 3219.5L1066.5 3186.5Z"},
        { id:"eph-355", d:"M1098 3220.5L1047 3265L1076 3297.5L1127 3243.5L1112 3229.5L1098 3220.5Z"},
        { id:"eph-359", d:"M918 3181L916.5 3193L939 3231L985.5 3190L954.5 3149.5L918 3181Z"},
        { id:"eph-360", d:"M986.5 3192L941 3232L964.5 3272.5L1014 3229L986.5 3192Z"},
        { id:"eph-361", d:"M1015.5 3231L965.5 3274L974 3288.5L995.5 3307L1044 3264.5L1015.5 3231Z"},
        { id:"eph-362", d:"M1045.5 3266.5L997.5 3308L1023 3330.5V3337L1019.5 3341L1029 3348.5L1074.5 3299L1045.5 3266.5Z"},
        { id:"eph-366", d:"M889.5 3205.5L872 3221.5L888 3288.5L936 3269L899 3207L889.5 3205.5Z"},
        { id:"eph-365", d:"M936.5 3271L888 3290L902.5 3352.5L956 3303L936.5 3271Z"},
        { id:"eph-364", d:"M957 3304.5L903.5 3353.5L941.5 3378L987.5 3330L957 3304.5Z"},
        { id:"eph-363", d:"M989.5 3331.5L943 3379.5L980 3404L1028 3349.5L1019 3342L1015 3346L1008 3347L989.5 3331.5Z"},
        { id:"eph-368", d:"M1213.5 2926L1184 2951.5L1228 3002L1265 2968.5L1213.5 2926Z"},
        { id:"eph-367", d:"M1152.5 2978V2988.5L1193 3032.5L1226 3002.5L1182 2953L1152.5 2978Z"},
        { id:"eph-329", d:"M1443.5 2684L1394.5 2697V2726.5H1476.5L1482.5 2716.5L1481 2705L1443.5 2684Z"},
        { id:"eph-330", d:"M1475.5 2728.5H1394V2770.5H1452L1475.5 2728.5Z"},
        { id:"eph-375", d:"M1253.5 2632.5L1170.5 2654.5L1196 2688.5L1263.5 2669.5L1253.5 2632.5Z"},
        { id:"eph-374", d:"M1264 2672L1197 2690.5L1222 2724.5L1287 2718.5L1264 2672Z"},
        { id:"eph-376", d:"M1188.5 2584.5L1137 2598.5L1134 2605.5L1169.5 2653L1204.5 2643.5L1188.5 2584.5Z"},
        { id:"eph-323", d:"M1412.5 2589.5L1374.5 2600L1393.5 2671.5L1431.5 2660.5L1412.5 2589.5Z"},
        { id:"eph-324", d:"M1372.5 2600.5L1334 2611L1352.5 2682L1391.5 2671.5L1372.5 2600.5Z"},
        { id:"eph-325", d:"M1331.5 2611.5L1293.5 2622L1312.5 2692.5L1351 2682L1331.5 2611.5Z"},
        { id:"eph-326", d:"M1291.5 2622.5L1255.5 2632L1266 2670.5L1289.5 2718.5L1307.5 2712.5L1303.5 2694.5L1310.5 2693L1291.5 2622.5Z"},
        { id:"eph-311", d:"M1656.5 2476L1639 2507L1707.5 2540.5L1725.5 2502L1656.5 2476Z"},
        { id:"eph-312", d:"M1638 2508.5L1619.5 2541.5L1689.5 2576L1706 2541.5L1638 2508.5Z"},
        { id:"eph-313", d:"M1618.5 2543.5L1601 2574.5L1671 2610.5C1679.22 2599.01 1683.22 2592.03 1688 2577.5L1618.5 2543.5Z"},
        { id:"eph-314", d:"M1599.5 2576.5L1583.5 2605L1647 2647.5L1670.5 2612.5L1599.5 2576.5Z"},
        { id:"eph-315", d:"M1582 2607L1563.5 2641L1624 2681.5L1645.5 2649L1582 2607Z"},
        { id:"eph-316", d:"M1562 2643L1541 2680L1602 2715.5L1622.5 2683L1562 2643Z"},
        { id:"eph-317", d:"M1540 2681.5L1519.5 2717.5L1580.5 2753L1600.5 2716.5L1540 2681.5Z"},
        { id:"eph-318", d:"M1518.5 2720L1498.5 2755.5L1558.5 2790.5L1579 2754.5L1518.5 2720Z"},
        { id:"eph-322", d:"M1549 2514L1514.5 2575.5L1550.5 2596L1585.5 2534.5L1549 2514Z"},
        { id:"eph-383", d:"M1480 2505.5L1480.76 2505.31M1480.76 2505.31L1517 2496L1547.5 2513L1512.5 2574.5L1496.5 2565L1480.76 2505.31Z"},
        { id:"eph-382", d:"M1478.5 2506.5L1432 2519L1448 2578L1494.5 2565.5L1478.5 2506.5Z"},
        { id:"eph-380", d:"M1382 2532.5L1336 2545L1351.5 2604L1398 2591.5L1382 2532.5Z"},
        { id:"eph-379", d:"M1333.5 2545.5L1287.5 2558L1303.5 2616.5L1349.5 2604.5L1333.5 2545.5Z"},
        { id:"eph-378", d:"M1285.5 2558.5L1239 2570.5L1254.5 2630L1301 2617L1285.5 2558.5Z"},
        { id:"eph-377", d:"M1237 2571.5L1191 2584L1207 2643L1253 2630.5L1237 2571.5Z"},
        { id:"eph-381", d:"M1384 2532L1384.45 2531.88M1384.45 2531.88L1430 2519.5L1446 2578L1400 2590.5L1384.45 2531.88Z"},
        { id:"eph-390", d:"M1308 2447L1271 2457L1290.5 2530.5L1328 2520.5L1308 2447Z"},
        { id:"eph-391", d:"M1269 2457.5L1232 2467.5L1252 2541L1289 2531L1269 2457.5Z"},
        { id:"eph-392", d:"M1230.5 2468L1192.5 2478L1212.5 2551.5L1250 2542L1230.5 2468Z"},
        { id:"eph-393", d:"M1191 2479L1154 2488.5L1173.5 2562L1211 2552L1191 2479Z"},
        { id:"eph-394", d:"M1151.5 2489L1114.5 2499L1134.5 2572.5L1171.5 2562.5L1151.5 2489Z"},
        { id:"eph-395", d:"M1113 2499.5L1075 2509.5L1095.5 2583.5L1132.5 2573L1113 2499.5Z"},
        { id:"eph-396", d:"M1074 2510L1036.5 2520L1056.5 2593.5L1093.5 2583L1074 2510Z"},
        { id:"eph-397", d:"M1034.5 2520.5L998.5 2530.5L1010.5 2596.5L1038.5 2598.5L1054.5 2594L1034.5 2520.5Z"},
        { id:"eph-398", d:"M996 2531.5L943 2546.5V2579.5L994.5 2596.5H1008L996 2531.5Z"},
        { id:"eph-387", d:"M1425.5 2415.5L1388 2425.5L1407.5 2499L1445 2489L1425.5 2415.5Z"},
        { id:"eph-388", d:"M1386 2426L1348.5 2436L1368 2509.5L1406 2499L1386 2426Z"},
        { id:"eph-389", d:"M1347 2436.5L1309.5 2446.5L1329.5 2520L1367 2510L1347 2436.5Z"},
        { id:"eph-320", d:"M1609.5 2418.5L1550.5 2432.5L1556.5 2460L1614 2483.5L1618.5 2476L1620 2463L1609.5 2418.5Z"},
        { id:"eph-321", d:"M1556 2461.5L1531 2496.5L1588.5 2529L1613.5 2485.5L1556 2461.5Z"},
        { id:"eph-386", d:"M1465 2405L1427 2415L1446.5 2488.5L1480.5 2479.5L1483.5 2474.5L1465 2405Z"},
        { id:"eph-384", d:"M1545.5 2422.5L1488 2438.5L1496.5 2471H1512.5L1516.5 2488.5L1529.5 2496L1554.5 2460.5L1545.5 2422.5Z"},
        { id:"eph-385", d:"M1536.5 2385L1466.5 2404L1485 2473L1494.5 2470.5L1485 2437L1545 2420.5L1536.5 2385Z"},
        { id:"epl-02", d:"M1024 2413.5L970.5 2429L997.5 2529L1051 2514L1024 2413.5Z"},
        { id:"epl-01", d:"M969 2428.5L914.5 2444.5L942.5 2544L995.5 2529.5L969 2428.5Z"},
        { id:"epl-04", d:"M1133.5 2382.5L1081 2397L1108.5 2498.5L1161.5 2484.5L1133.5 2382.5Z"},
        { id:"epl-03", d:"M1079 2398L1026.5 2413L1053 2513.5L1106.5 2499.5L1079 2398Z"},
        { id:"epl-10", d:"M1458.5 2295L1407 2308L1434 2411L1486.5 2396.5L1458.5 2295Z"},
        { id:"epl-09", d:"M1404 2308L1352 2321.5L1379.5 2425L1432 2411L1404 2308Z"},
        { id:"epl-08", d:"M1349.5 2321.5L1298 2335.5L1326.5 2440L1377.5 2426L1349.5 2321.5Z"},
        { id:"epl-07", d:"M1296.5 2336.5L1244.5 2351L1272.5 2454L1324.5 2440.5L1296.5 2336.5Z"},
        { id:"epl-06", d:"M1243 2351L1190.5 2365.5L1218.5 2469.5L1270.5 2455L1243 2351Z"},
        { id:"epl-05", d:"M1188.5 2366.5L1135.5 2381L1164 2483.5L1216.5 2469L1188.5 2366.5Z"},
        { id:"eph-305", d:"M1679.5 2240L1617.5 2256L1608 2271L1614.5 2296L1687 2282L1679.5 2240Z"},
        { id:"eph-306", d:"M1687 2284L1614.5 2297.5L1624 2335.5L1695 2322L1687 2284Z"},
        { id:"eph-307", d:"M1695.5 2324.5L1625 2337.5L1634 2376.5L1706.5 2362.5C1702.1 2349.54 1699.71 2340.77 1695.5 2324.5Z"},
        { id:"eph-308", d:"M1707 2365L1634.5 2378.5L1642.5 2416L1719.5 2401.5L1707 2365Z"},
        { id:"eph-309", d:"M1720 2403.5L1643.5 2417.5L1653 2454.5L1726.5 2440.5L1720 2403.5Z"},
        { id:"eph-310", d:"M1726.5 2442.5L1652.5 2456.5L1657 2474.5L1726.5 2500.5L1734 2486.5L1726.5 2442.5Z"},
        { id:"epl-12", d:"M1560.5 2269.5L1516.5 2280.5L1543.5 2381.5L1597.5 2367L1576.5 2278.5L1560.5 2269.5Z"},
        { id:"epl-11", d:"M1514.5 2281L1460.5 2294.5L1488.5 2396.5L1541.5 2382L1514.5 2281Z"},
        { id:"eph-280", d:"M984.5 1951.5L990.5 1959.5L1057.5 1970.5L1064 1932.5L990.5 1918.5L984.5 1951.5Z"},
        { id:"eph-304", d:"M1360.5 2065L1355 2124L1411.5 2130.5L1413.5 2084.5L1360.5 2065Z"},
        { id:"eph-303", d:"M1358.5 2064.5L1316.5 2049.5L1305.5 2118L1352 2124L1358.5 2064.5Z"},
        { id:"eph-302", d:"M1298 2042.5L1275 2038.5L1263.5 2111L1302.5 2117.5L1314 2048.5L1298 2042.5Z"},
        { id:"eph-301", d:"M1272.5 2038.5L1232 2032.5L1221.5 2104L1261 2111L1272.5 2038.5Z"},
        { id:"eph-300", d:"M1230.5 2031.5L1192 2025.5L1179.5 2097.5L1219.5 2103.5L1230.5 2031.5Z"},
        { id:"eph-299", d:"M1149.5 2019L1138 2090L1177.5 2096.5L1189 2025L1149.5 2019Z"},
        { id:"eph-298", d:"M1147 2018.5L1107.5 2011.5L1095 2083L1135.5 2090L1147 2018.5Z"},
        { id:"eph-297", d:"M1105.5 2012L1065.5 2005.5L1053.5 2076.5L1093 2083L1105.5 2012Z"},
        { id:"epm-45", d:"M1323.5 2123L1312.5 2189L1409.5 2165L1411.5 2132.5L1323.5 2123Z"},
        { id:"epm-43", d:"M1321.5 2122.5L1207.5 2104L1201.5 2138L1216.5 2140.5L1221 2146.5L1314.5 2162.5L1321.5 2122.5Z"},
        { id:"epm-44", d:"M1220 2149.5C1219.6 2150.7 1213.5 2191.33 1210.5 2211.5L1216 2216L1310.5 2190L1314.5 2165C1283.17 2159.33 1220.4 2148.3 1220 2149.5Z"},
        { id:"epm-42", d:"M1140 2092.5L1129 2159.5L1194.5 2170.5L1205.5 2103L1140 2092.5Z"},
        { id:"epm-41", d:"M1128.5 2161.5L1114.5 2244L1178.5 2226.5L1186.5 2217.5L1193.5 2172L1128.5 2161.5Z"},
        { id:"eph-295", d:"M1063 2005L1022.5 1999L1011.5 2069.5L1051.5 2075.5L1063 2005Z"},
        { id:"epm-38", d:"M1037.5 2075.5L1035 2095V2096.5L1052.5 2099L1056.5 2105L1052.5 2130.5L1129.5 2143L1138 2092.5L1037.5 2075.5Z"},
        { id:"epm-39", d:"M1129 2145.5L1053 2133H1051.5L1042.5 2190L1119.5 2202.5L1129 2145.5Z"},
        { id:"epm-40", d:"M1042.5 2192.5L1031 2261V2263L1036 2266.5L1113 2245L1119 2204.5L1042.5 2192.5Z"},
        { id:"eph-295_2", d:"M1020.5 1998L980.5 1991.5L969.5 2062.5L1009.5 2069L1020.5 1998Z"},
        { id:"eph-294", d:"M935.5 1991L925.5 2055L967.5 2062L979 1991L951 1987.5L935.5 1991Z"},
        { id:"eph-293", d:"M880 2005.5L871 2014.5L865 2045.5L923.5 2054.5L933.5 1992L880 2005.5Z"},
        { id:"epm-37", d:"M951.5 2061.5L942.5 2113L1028 2127L1035 2075L951.5 2061.5Z"},
        { id:"epm-36", d:"M942.5 2115L933 2166L1019 2180.5L1027 2129L942.5 2115Z"},
        { id:"epm-35", d:"M1018 2182L932.5 2168L924 2218.5L1010.5 2232.5L1018 2182Z"},
        { id:"epm-34", d:"M1009.5 2235L930 2221.5L924.5 2261.5L934 2295.5L996 2278.5L1004.5 2268.5L1009.5 2235Z"},
        { id:"epm-29", d:"M949.5 2060.5L865.5 2047.5L855.5 2099L940.5 2112.5L949.5 2060.5Z"},
        { id:"epm-30", d:"M855.5 2100.5L845.5 2151.5L931 2165.5L940 2114.5L855.5 2100.5Z"},
        { id:"epm-31", d:"M845.5 2154L836 2204.5L922.5 2218L930.5 2167L845.5 2154Z"},
        { id:"epm-32", d:"M831.5 2223.5L830.5 2263L922.5 2259.5L928 2221.5L835 2206L831.5 2223.5Z"},
        { id:"epm-33", d:"M829 2281.5L834.5 2316.5L843 2321.5L932.5 2296L922 2261L830.5 2265.5L829 2281.5Z"},
        { id:"eph-289", d:"M1246.5 1836L1212 1878L1239.5 1901.5H1273.5L1280.5 1851.5L1270.5 1850L1246.5 1836Z"},
        { id:"eph-272", d:"M1219.5 1712.5L1161.5 1764L1193 1792.5L1244.5 1735.5L1219.5 1712.5Z"},
        { id:"eph-290", d:"M1275 1903.5H1239.5L1238 1917L1312.5 1923.5L1319 1857.5L1282.5 1852L1275 1903.5Z"},
        { id:"eph-288", d:"M1200 1801.5L1164.5 1840.5L1210 1877L1244.5 1834.5L1223 1822.5L1200 1801.5Z"},
        { id:"eph-287", d:"M1160 1765.5L1118 1803L1163.5 1839L1198.5 1800L1160 1765.5Z"},
        { id:"eph-291", d:"M1311.5 1926L1238.5 1919.5L1234 1958.5L1308.5 1965L1311.5 1926Z"},
        { id:"eph-292", d:"M1308 1968L1234.5 1960.5L1231.5 1990.5L1239 1999.5L1305 2009L1308 1968Z"},
        { id:"eph-284", d:"M1163.5 1869L1151.5 1934.5L1210.5 1945.5L1213 1916.5L1208.5 1904.5L1163.5 1869Z"},
        { id:"eph-283", d:"M1210 1947.5L1151.5 1936.5L1142.5 1983L1199 1993L1206.5 1987L1210 1947.5Z"},
        { id:"eph-286", d:"M1116 1804L1085.5 1831L1080 1859.5L1148.5 1870.5L1150.5 1858.5L1134.5 1845.5L1146 1828L1116 1804Z"},
        { id:"eph-282", d:"M1152.5 1911.5L1113.5 1905.5L1100 1977.5L1139.5 1982.5L1152.5 1911.5Z"},
        { id:"eph-281", d:"M1112 1905L1072.5 1898.5L1058.5 1971L1098.5 1976.5L1112 1905Z"},
        { id:"eph-279", d:"M998.5 1877.5L991.5 1916L1064.5 1930.5L1071.5 1892L998.5 1877.5Z"},
        { id:"eph-273", d:"M1159.5 1669.5L1136.5 1690.5L1183 1742.5L1217.5 1711L1172.5 1669.5H1159.5Z"},
        { id:"eph-275", d:"M1135.5 1692L1104 1720L1150 1771.5L1181 1743.5L1135.5 1692Z"},
        { id:"eph-276", d:"M1102 1721.5L1070.5 1749.5L1116.5 1801L1148 1773L1102 1721.5Z"},
        { id:"eph-276_2", d:"M1069 1750.5L1036.5 1780L1085.5 1829L1115 1802L1069 1750.5Z"},
        { id:"eph-277", d:"M1013 1800L1006.5 1835L1080.5 1849L1084 1830.5L1034.5 1781L1013 1800Z"},
        { id:"eph-278", d:"M1006.5 1838L999.5 1875.5L1072.5 1889L1080 1852L1006.5 1838Z"},
        { id:"eph-285", d:"M1079.5 1861.5L1073.5 1896.5L1154.5 1909.5L1162.5 1867.5L1152.5 1860L1149.5 1872.5L1079.5 1861.5Z"},
        { id:"eph-268", d:"M1340 1683L1288 1740.5L1312 1762L1333.5 1768.5L1355.5 1697L1340 1683Z"},
        { id:"eph-267", d:"M1310.5 1656L1258.5 1713L1286 1739L1338.5 1681L1310.5 1656Z"},
        { id:"eph-266", d:"M1261 1647L1226.5 1685L1257 1712L1308.5 1655L1301.5 1648.5L1299.5 1638L1261 1647Z"},
        { id:"eph-264", d:"M1282.5 1561.5L1237 1602L1261.5 1644.5L1299 1636.5L1282.5 1561.5Z"},
        { id:"eph-265", d:"M1235.5 1603L1190.5 1643V1652L1226 1684L1260.5 1645.5L1235.5 1603Z"},
        { id:"eph-269", d:"M1299.5 1777.5L1273 1848.5L1318.5 1856L1323 1802.5L1332.5 1770.5L1311.5 1764L1299.5 1777.5Z"},
        { id:"eph-270", d:"M1275.5 1763.5L1224 1820L1271 1848L1298 1777L1275.5 1763.5Z"},
        { id:"eph-271", d:"M1246 1737L1195 1793.5L1222.5 1819L1274 1762L1246 1737Z"},
        { id:"eph-255", d:"M819.5 1594L770.5 1650L800.5 1676L849 1619.5L819.5 1594Z"},
        { id:"eph-256", d:"M788 1567L739.5 1623L769 1649L817.5 1592.5L788 1567Z"},
        { id:"eph-257", d:"M757 1539.5L712.5 1590V1600.5L738 1621.5L786.5 1565L757 1539.5Z"},
        { id:"eph-263", d:"M860.5 1761.5L815.5 1813.5L859 1852L880.5 1778.5L860.5 1761.5Z"},
        { id:"eph-262", d:"M827 1732.5L782 1784.5L814 1812.5L859 1760.5L827 1732.5Z"},
        { id:"eph-261", d:"M793.5 1703.5L748 1755.5L780.5 1783L825.5 1731.5L793.5 1703.5Z"},
        { id:"eph-260", d:"M759.5 1674.5L714.5 1726.5L746.5 1754.5L791.5 1702L759.5 1674.5Z"},
        { id:"eph-259", d:"M725.5 1645.5L680.5 1697.5L712.5 1725.5L758 1673L725.5 1645.5Z"},
        { id:"eph-258", d:"M686.5 1620.5L646 1667.5L679 1696L724 1644L697 1620.5H686.5Z"},
        { id:"eph-247", d:"M838.5 1509L790 1564.5L819 1591L868 1534.5L838.5 1509Z"},
        { id:"eph-227", d:"M903 1438.5L858 1491L857 1492L889.5 1519.5L935 1466.5L903 1438.5Z"},
        { id:"eph-226", d:"M869.5 1409L828.5 1456.5V1466L855.5 1490.5L901.5 1437L869.5 1409Z"},
        { id:"eph-241", d:"M1056 1497.5L1021.5 1538L1063 1573.5L1097.5 1533.5L1056 1497.5Z"},
        { id:"eph-242", d:"M987 1471L968 1492.5L1019.5 1536.5L1054 1496.5L1025.5 1472H1014L1005.5 1481.5H999L987 1471Z"},
        { id:"eph-233", d:"M1103.5 1611.5L1058 1664.5L1081.5 1685H1093L1140 1643L1103.5 1611.5Z"},
        { id:"eph-232", d:"M1070 1583L1024.5 1635.5L1056 1663L1102 1610.5L1070 1583Z"},
        { id:"eph-231", d:"M1036.5 1554L991 1606.5L1022.5 1634.5L1068.5 1581.5L1036.5 1554Z"},
        { id:"eph-230", d:"M1003 1525L958 1577.5L989.5 1605.5L1035 1552.5L1003 1525Z"},
        { id:"eph-229", d:"M970 1496.5L924.5 1549L956.5 1576.5L1001.5 1523.5L970 1496.5Z"},
        { id:"eph-245", d:"M1015.5 1342.5L983 1376.5L1030.5 1417.5L1058 1386V1376.5L1015.5 1342.5Z"},
        { id:"eph-244", d:"M981.5 1378.5L951 1413.5L999 1454.5L1029.5 1419L981.5 1378.5Z"},
        { id:"eph-243", d:"M950 1415L919.5 1450L967 1491L997 1456L950 1415Z"},
        { id:"eph-223", d:"M958.5 1305L934 1334.5L981 1375L1013.5 1341L969 1305H958.5Z"},
        { id:"eph-224", d:"M932.5 1336.5L902 1371.5L949.5 1412L979.5 1376.5L932.5 1336.5Z"},
        { id:"eph-225", d:"M901 1372.5L870.5 1408L918 1448.5L948 1413.5L901 1372.5Z"},
        { id:"eph-228", d:"M936 1468L891 1520.5L922.5 1547.5L968 1495L936 1468Z"},
        { id:"eph-246", d:"M802.5 1486.5L758 1538L788 1564L837 1507.5L813 1486.5H802.5Z"},
        { id:"eph-252", d:"M913 1674L864 1731L881 1746L896 1748L904 1743.5L915.5 1734.5L923.5 1726L944 1700.5L913 1674Z"},
        { id:"eph-253", d:"M882 1647.5L833 1703.5L862.5 1729.5L911.5 1673L882 1647.5Z"},
        { id:"eph-254", d:"M850.5 1620.5L801.5 1677L831 1703L880 1646L850.5 1620.5Z"},
        { id:"eph-251", d:"M963 1616L914 1672.5L945 1699L987.5 1647.5V1637.5L963 1616Z"},
        { id:"eph-250", d:"M932 1589.5L883 1645.5L912.5 1671.5L962 1615L932 1589.5Z"},
        { id:"eph-249", d:"M900.5 1562.5L851.5 1619L881.5 1644.5L930.5 1588.5L900.5 1562.5Z"},
        { id:"eph-248", d:"M869.5 1535.5L820.5 1592L850 1618L899 1561L869.5 1535.5Z"},
        { id:"eph-188", d:"M1164.5 1013L1140 1042.5L1198.5 1091.5L1218.5 1067.5L1217.5 1057L1164.5 1013Z"},
        { id:"eph-189", d:"M1138.5 1044L1113.5 1073.5L1172 1122.5L1197 1093L1138.5 1044Z"},
        { id:"eph-190", d:"M1112.5 1075L1087.5 1104L1146.5 1154L1171 1124L1112.5 1075Z"},
        { id:"eph-191", d:"M1086.5 1106L1062 1135L1120.5 1184.5L1145 1155L1086.5 1106Z"},
        { id:"eph-192", d:"M1060.5 1136.5L1036 1166L1094.5 1215.5L1119.5 1186L1060.5 1136.5Z"},
        { id:"eph-193", d:"M1035 1167.5L1010.5 1196L1079.5 1251.5L1086.5 1243L1081.5 1238.5V1231L1093 1216.5L1035 1167.5Z"},
        { id:"eph-187", d:"M1100 967L1079.5 992L1138.5 1041.5L1163.5 1011.5L1110.5 967H1100Z"},
        { id:"eph-186", d:"M1078 993.5L1053.5 1022.5L1112 1072L1137 1042.5L1078 993.5Z"},
        { id:"eph-185", d:"M1052 1024.5L1028 1053.5L1086 1103L1110.5 1073.5L1052 1024.5Z"},
        { id:"eph-184", d:"M1026.5 1055L1002 1084.5L1060.5 1134L1085.5 1104.5L1026.5 1055Z"},
        { id:"eph-183", d:"M1000.5 1086.5L976 1115.5L1034.5 1164.5L1059 1135L1000.5 1086.5Z"},
        { id:"eph-182", d:"M975 1117L950 1146.5L1009 1194.5L1033.5 1166L975 1117Z"},
        { id:"eph-214", d:"M1223.5 820L1198.5 849.5L1257.5 899L1282.5 869.5L1223.5 820Z"},
        { id:"eph-213", d:"M1197.5 851L1173 880.5L1231.5 929.5L1256 900.5L1197.5 851Z"},
        { id:"eph-212", d:"M1171.5 882L1147 911.5L1206 960.5L1230 931L1171.5 882Z"},
        { id:"eph-211", d:"M1145.5 913.5L1126 937V947L1179 991.5L1204 962L1145.5 913.5Z"},
        { id:"eph-216", d:"M1194.5 746L1142 802L1165.5 825H1175.5L1221.5 771L1194.5 746Z"},
        { id:"eph-217", d:"M1166 719L1113 774.5L1140 801L1193 744.5L1166 719Z"},
        { id:"eph-218", d:"M1137.5 691L1084 747.5L1111 773L1164.5 717L1137.5 691Z"},
        { id:"eph-219", d:"M1108.5 664L1055.5 720L1082.5 746.5L1136 690L1108.5 664Z"},
        { id:"eph-220", d:"M1079.5 636.5L1026.5 692.5L1053.5 718.5L1107 662.5L1079.5 636.5Z"},
        { id:"eph-221", d:"M1050.5 609L997.5 665L1024.5 691.5L1078 635.5L1050.5 609Z"},
        { id:"eph-222", d:"M1021.5 582L955 652.5L964 661.5L974.5 650.5H982L996 664L1049.5 608L1021.5 582Z"},
        { id:"eph-100", d:"M1391.5 815.5L1347 869L1379.5 896.5L1425.5 847.5L1391.5 815.5Z"},
        { id:"eph-206", d:"M1309.5 840L1285 869.5L1343.5 918.5L1368.5 889.5L1309.5 840Z"},
        { id:"eph-207", d:"M1283.5 871L1259 900L1317.5 949.5L1342 920L1283.5 871Z"},
        { id:"eph-208", d:"M1257.5 902.5L1233.5 931L1291.5 980L1316 951L1257.5 902.5Z"},
        { id:"eph-209", d:"M1232 933L1207.5 962L1265.5 1011L1290.5 981.5L1232 933Z"},
        { id:"eph-210", d:"M1206 963.5L1181.5 993L1233.5 1037.5L1244.5 1036.5L1264.5 1012.5L1206 963.5Z"},
        { id:"eph-215", d:"M1249 789.5L1225 818.5L1225.5 819.5L1283.5 868L1308 839L1249 789.5Z"},
        { id:"eph-157", d:"M1227 1218L1202 1247.5L1260 1296L1285 1266.5L1227 1218Z"},
        { id:"eph-158", d:"M1201 1249.5L1176 1279L1233.5 1327.5L1258.5 1298L1201 1249.5Z"},
        { id:"eph-159", d:"M1174.5 1280.5L1150 1309.5L1217 1363.5L1224 1355.5L1220.5 1352.5V1343L1232.5 1329L1174.5 1280.5Z"},
        { id:"Vector 603", d:"M1185.5 1341L1138 1399L1168 1423.5L1215.5 1365.5L1185.5 1341Z"},
        { id:"Vector 604", d:"M1153.5 1315L1106 1373L1136 1397.5L1183.5 1340L1153.5 1315Z"},
        { id:"eph-101", d:"M1430 852L1402.5 880.5L1458.5 933L1483.5 903L1430 852Z"},
        { id:"eph-205", d:"M1383 901.5L1378 917L1433 963.5L1457 935L1401.5 882L1383 901.5Z"},
        { id:"eph-204", d:"M1376.5 919L1351.5 948.5L1409.5 997L1434 967.5L1376.5 919Z"},
        { id:"eph-203", d:"M1350.5 950L1325.5 979.5L1383.5 1028L1408 998.5L1350.5 950Z"},
        { id:"eph-202", d:"M1324 981.5L1299.5 1011L1357 1059.5L1382 1030L1324 981.5Z"},
        { id:"eph-201", d:"M1298 1012.5L1273 1041.5L1331.5 1090.5L1356 1061L1298 1012.5Z"},
        { id:"eph-200", d:"M1247 1073.5L1247.4 1073.03M1247.4 1073.03L1272.5 1043.5L1329.5 1092L1305 1121.5L1247.4 1073.03Z"},
        { id:"eph-199", d:"M1246 1075L1221 1104.5L1279 1152.5L1303.5 1123L1246 1075Z"},
        { id:"eph-198", d:"M1220 1105.5L1195.5 1135L1253 1183.5L1277.5 1154L1220 1105.5Z"},
        { id:"eph-197", d:"M1194 1137L1169 1166.5L1227 1215L1251.5 1186L1194 1137Z"},
        { id:"eph-196", d:"M1167.5 1168.5L1143 1197.5L1200.5 1246.5L1225 1216.5L1167.5 1168.5Z"},
        { id:"eph-195", d:"M1141 1199.5L1117 1228.5L1174.5 1277L1199 1248L1141 1199.5Z"},
        { id:"eph-194", d:"M1080.5 1254L1081.26 1253.05M1081.26 1253.05L1088.5 1244L1092 1247.5H1101.5L1115 1231L1173 1279.5L1148.5 1308L1081.26 1253.05Z"},
        { id:"eph-176", d:"M1121.5 1289.5L1074.5 1347L1104 1372L1151.5 1313.5L1121.5 1289.5Z"},
        { id:"eph-177", d:"M1089.5 1263L1042.5 1321L1072.5 1345.5L1120 1288L1089.5 1263Z"},
        { id:"eph-178", d:"M1057.5 1237.5L1010.5 1295L1040.5 1320L1088 1262L1057.5 1237.5Z"},
        { id:"eph-179", d:"M1026 1211.5L978.5 1269L1008.5 1294L1056.5 1236L1026 1211.5Z"},
        { id:"eph-180", d:"M994 1185.5L946.5 1243.5L976.5 1268.5L1024.5 1210L994 1185.5Z"},
        { id:"eph-181", d:"M962 1160L915 1218L945 1241.5L992.5 1184.5L962 1160Z"},
        { id:"eph-236", d:"M1183 1478.5L1147 1520L1187 1555L1224.5 1513L1183 1478.5Z"},
        { id:"eph-235", d:"M1145.5 1522L1121.5 1550L1186 1603L1206.5 1584.5L1186 1557L1145.5 1522Z"},
        { id:"eph-234", d:"M1139 1568L1105.5 1609.5L1104.5 1610L1142 1642L1183.5 1604.5L1139 1568Z"},
        { id:"eph-240", d:"M1099.5 1535L1065 1575.5L1103 1608.5L1137.5 1566.5L1118 1550L1128 1538.5L1112 1524.5L1099.5 1535Z"},
        { id:"eph-238", d:"M1078 1401.5L1041 1444.5L1039.5 1446L1041 1455L1068 1478L1115 1423.5L1088 1401.5H1078Z"},
        { id:"eph-237", d:"M1116.5 1424.5L1094 1451L1157.5 1504L1181 1477L1116.5 1424.5Z"},
        { id:"eph-239", d:"M1092 1453L1069.5 1479.5L1112.5 1516.5V1522L1129.5 1537L1156 1506L1092 1453Z"},
        { id:"eph-151", d:"M1383.5 1031.5L1358.5 1060.5L1416.5 1109L1441.5 1080L1383.5 1031.5Z"},
        { id:"eph-152", d:"M1357.5 1062.5L1333 1091.5L1391 1140.5L1416 1111L1357.5 1062.5Z"},
        { id:"eph-153", d:"M1331.5 1093.5L1306.5 1123L1307.5 1123.5L1365 1171.5L1389.5 1142L1331.5 1093.5Z"},
        { id:"eph-154", d:"M1305.5 1125L1281 1154L1338.5 1203L1364 1173.5L1305.5 1125Z"},
        { id:"eph-155", d:"M1279.5 1155.5L1254.5 1185.5L1312.5 1234L1337 1204L1279.5 1155.5Z"},
        { id:"eph-156", d:"M1227.5 1217L1229 1215.24M1229 1215.24L1253.5 1186.5L1311.5 1235.5L1286 1265L1229 1217V1215.24Z"},
        { id:"eph-102", d:"M1485.5 904L1435 964.5L1463.5 990L1513.5 930.5L1485.5 904Z"},
        { id:"eph-149", d:"M1435.5 969L1411 998.5L1468.5 1047.5L1494 1017.5L1435.5 969Z"},
        { id:"eph-150", d:"M1383.5 1030.5L1385.19 1028.78M1385.19 1028.78L1409.5 999.5L1467.5 1049L1443.5 1078.5L1385.19 1028.78Z"},
        { id:"eph-170", d:"M1313 1444.5L1266 1502.5L1280.5 1515.5H1293L1349.5 1480.5V1475L1313 1444.5Z"},
        { id:"eph-171", d:"M1280.5 1419L1234 1476.5L1264 1501.5L1311 1443L1280.5 1419Z"},
        { id:"eph-172", d:"M1249 1392.5L1202 1451L1231.5 1475L1279 1417L1249 1392.5Z"},
        { id:"eph-173", d:"M1217 1367L1170 1424.5L1200 1449.5L1247 1391.5L1217 1367Z"},
        { id:"eph-160", d:"M1225.5 1356.5L1218.5 1365.5L1219.5 1366L1285.5 1419.5L1309.5 1390.5L1253.5 1344.5L1240 1360.5H1230L1225.5 1356.5Z"},
        { id:"eph-161", d:"M1280.5 1311.5L1254.5 1342.5L1310.5 1389.5L1336.5 1359L1280.5 1311.5Z"},
        { id:"eph-169", d:"M1317.5 1385L1287.5 1421L1351.5 1473L1361.5 1468L1338.5 1449.5L1361.5 1422L1317.5 1385Z"},
        { id:"eph-168", d:"M1385.5 1397.5L1342 1449L1363.5 1466.5L1370 1468L1425.5 1436.5V1430.5L1385.5 1397.5Z"},
        { id:"eph-132", d:"M1558.5 1455.5L1492 1474L1496 1511.5L1552.5 1504V1490L1566.5 1488.5L1558.5 1455.5Z"},
        { id:"eph-135", d:"M1545.5 1406L1476 1445.5L1491 1472L1558 1453.5L1545.5 1406Z"},
        { id:"eph-136", d:"M1474.5 1446.5L1426.5 1475L1456.5 1516.5L1494 1512L1489.5 1473L1474.5 1446.5Z"},
        { id:"eph-137", d:"M1424.5 1475.5L1367.5 1509L1388 1537L1454 1517.5L1424.5 1475.5Z"},
        { id:"eph-166", d:"M1462.5 1354L1419 1405.5L1439 1422.5L1446 1424.5L1500.5 1393.5V1385.5L1462.5 1354Z"},
        { id:"eph-165", d:"M1427.5 1324.5L1384.5 1376L1417.5 1404.5L1461 1352.5L1427.5 1324.5Z"},
        { id:"eph-164", d:"M1393 1295.5L1349.5 1347L1382.5 1375L1426 1323.5L1393 1295.5Z"},
        { id:"eph-163", d:"M1329.5 1253L1308.5 1278L1364.5 1325.5L1391 1294.5L1340 1251.5L1329.5 1253Z"},
        { id:"eph-162", d:"M1307 1280L1281.5 1310L1337.5 1357L1363 1327L1307 1280Z"},
        { id:"eph-167", d:"M1318.5 1383.5L1318.9 1383.03M1318.9 1383.03L1348 1348.5L1437 1423.5L1427.5 1429.5L1385 1394.5L1363 1420.5L1318.9 1383.03Z"},
        { id:"eph-127", d:"M1759 1495.5L1722 1499L1728 1574L1765.5 1570.5L1759 1495.5Z"},
        { id:"eph-128", d:"M1719 1498.5L1682 1502L1688.5 1578L1726 1574L1719 1498.5Z"},
        { id:"eph-129", d:"M1679.5 1502L1641.5 1505.5L1648.5 1581L1685.5 1577.5L1679.5 1502Z"},
        { id:"eph-130", d:"M1639.5 1505.5L1601.5 1508.5L1608 1584L1646.5 1581.5L1639.5 1505.5Z"},
        { id:"eph-131", d:"M1599.5 1509L1557 1513L1573 1587L1606 1584.5L1599.5 1509Z"},
        { id:"eph-126", d:"M1799 1492L1762 1495L1768 1570.5L1806 1566.5L1799 1492Z"},
        { id:"eph-125", d:"M1841.5 1488.5L1801.5 1491.5L1808.5 1566.5L1844 1564L1841.5 1488.5Z"},
        { id:"eph-120", d:"M1786 1341L1740.5 1344.5L1745.5 1407L1791 1403L1786 1341Z"},
        { id:"eph-121", d:"M1691.5 1356L1696.5 1411L1743.5 1408L1738 1345L1698 1348.5L1691.5 1356Z"},
        { id:"eph-124", d:"M1794 1405.5L1800 1468H1821L1826.5 1473V1487.5L1841.5 1486L1839.5 1404L1794 1405.5Z"},
        { id:"eph-123", d:"M1791.5 1406L1746 1409.5L1750.5 1472L1797 1469L1791.5 1406Z"},
        { id:"eph-122", d:"M1743 1410.5L1697 1414L1701.5 1469.5L1709 1476.5L1748.5 1473L1743 1410.5Z"},
        { id:"eph-134", d:"M1671.5 1424.5L1616.5 1430.5L1621.5 1484L1668.5 1480.5L1675.5 1472.5L1671.5 1424.5Z"},
        { id:"eph-133", d:"M1614.5 1430.5L1556.5 1436.5L1568.5 1488.5L1619.5 1484.5L1614.5 1430.5Z"},
        { id:"eph-119", d:"M1837 1338L1789 1341L1793.5 1403.5L1839.5 1402.5L1837 1338Z"},
        { id:"eph-142", d:"M1433.5 1243L1377.5 1196.5L1356.5 1221.5V1232L1407 1274.5L1433.5 1243Z"},
        { id:"eph-144", d:"M1405.5 1163L1406.02 1162.38M1406.02 1162.38L1431.5 1132L1487 1178.5L1462 1209L1406.02 1162.38Z"},
        { id:"eph-138", d:"M1550.5 1294.5L1501.5 1353L1524.5 1372.5H1536.5L1580.5 1320L1550.5 1294.5Z"},
        { id:"eph-139", d:"M1520 1268.5L1470.5 1327L1500 1352L1549.5 1292.5L1520 1268.5Z"},
        { id:"eph-140", d:"M1489.5 1242.5L1440 1301.5L1469 1326L1518.5 1267.5L1489.5 1242.5Z"},
        { id:"eph-141", d:"M1458.5 1216.5L1408.5 1276H1410L1438 1300L1487.5 1241L1458.5 1216.5Z"},
        { id:"eph-143", d:"M1405 1164L1379 1194.5L1435 1241.5L1460.5 1211L1405 1164Z"},
        { id:"eph-147", d:"M1512.5 1035.5L1487 1065.5L1542.5 1113L1568 1082.5L1512.5 1035.5Z"},
        { id:"eph-103", d:"M1514.5 931.5L1465 990.5L1495.5 1016L1543 959L1514.5 931.5Z"},
        { id:"eph-148", d:"M1530.5 983L1498.5 1021L1544.5 1059.5L1576.5 1022L1530.5 983Z"},
        { id:"eph-146", d:"M1485.5 1068L1459.5 1098.5L1515.5 1145L1541 1114.5L1485.5 1068Z"},
        { id:"eph-115", d:"M1601.5 1234L1552 1293L1581.5 1317.5L1627 1264.5L1625.5 1254L1601.5 1234Z"},
        { id:"eph-114", d:"M1570.5 1208L1521 1267L1522 1267.5L1550 1291L1599 1232.5L1570.5 1208Z"},
        { id:"eph-113", d:"M1540 1182L1490 1241.5L1519 1266L1569 1207L1540 1182Z"},
        { id:"eph-112", d:"M1509 1157L1460 1215L1488.5 1240L1538 1181.5L1509 1157Z"},
        { id:"eph-145", d:"M1458.5 1100L1432.5 1130.5L1489 1177.5L1514.5 1147L1458.5 1100Z"},
        { id:"eph-107", d:"M1692.5 1101L1655.5 1148L1697 1180L1724.5 1147L1723.5 1129.5L1692.5 1101Z"},
        { id:"eph-108", d:"M1664 1157.5L1617 1217.5L1640.5 1236.5H1650L1695.5 1182L1664 1157.5Z"},
        { id:"eph-106", d:"M1656.5 1067L1617 1117.5L1654 1146L1691 1099.5L1656.5 1067Z"},
        { id:"eph-109", d:"M1632 1132.5L1586 1191L1615.5 1216L1662.5 1156.5L1632 1132.5Z"},
        { id:"eph-110", d:"M1599.5 1107L1554 1164.5L1584 1190L1630.5 1131L1599.5 1107Z"},
        { id:"eph-104", d:"M1592.5 1005.5L1546 1061L1579 1088L1621.5 1033.5L1592.5 1005.5Z"},
        { id:"eph-111", d:"M1570 1084L1510.5 1154.5L1520.5 1163L1530.5 1152H1539L1553 1163L1597.5 1105.5L1570 1084Z"},
        { id:"eph-105", d:"M1623.5 1035L1580.5 1089L1615 1116L1655 1065.5L1623.5 1035Z"},
        { id:"eph-91", d:"M1537.5 790L1484.5 846L1512 872.5L1565 816.5L1537.5 790Z"},
        { id:"eph-87", d:"M1510 762L1510.55 761.424M1510.55 761.424L1564 705L1590.5 731L1537.5 787.5L1510.55 761.424Z"},
        { id:"eph-90", d:"M1509 763.5L1456 819L1483 845L1536 789L1509 763.5Z"},
        { id:"eph-88", d:"M1529.5 682.5L1481 733.5L1508.5 760L1562 704L1539.5 682.5H1529.5Z"},
        { id:"eph-89", d:"M1431.5 796.5L1454 817.5L1507 761.5L1480 735.5L1431.5 786V796.5Z"},
        { id:"eph-64", d:"M1488 633.5L1434 690.5L1462.5 716.5L1510.5 665.5V654L1488 633.5Z"},
        { id:"eph-63", d:"M1433 692C1415.5 709.833 1380.5 745.9 1380.5 747.5H1379L1401.5 769H1413.5L1460.5 717.5L1433 692Z"},
        { id:"eph-65", d:"M1459 606L1405.5 662.5L1433 688.5L1486.5 632.5L1459 606Z"},
        { id:"eph-62", d:"M1404 664.5L1351 720C1350.24 720.8 1369.02 738 1378.5 746.5L1432 690.5L1404.5 664L1404 664.5Z"},
        { id:"eph-66", d:"M1430 579L1377.5 634.5L1377 635.5L1403.5 661L1457 605L1430 579Z"},
        { id:"eph-61", d:"M1375.5 637L1322 693C1320.8 694.256 1339.83 711.19 1349.5 719.5L1403 663L1375.5 637Z"},
        { id:"eph-67", d:"M1401.5 552L1348 606.5L1347.5 607.5L1376 634.5L1429 577.5L1401.5 552Z"},
        { id:"eph-60", d:"M1347 610.5L1294 666.5L1321 691.5L1374.5 636L1347 610.5Z"},
        { id:"eph-68", d:"M1368.5 527L1319 580L1347 606L1400.5 550.5L1376 527H1368.5Z"},
        { id:"eph-69", d:"M1291.5 552L1354 489L1364 499.5L1356 516L1367.5 526L1318 578L1291.5 552ZM1291.5 552V553.5V550V552Z"},
        { id:"eph-58", d:"M1289 556C1288.2 555.2 1253.67 593 1236.5 612L1263.5 636L1315 581C1306.67 573 1289.8 556.8 1289 556Z"},
        { id:"eph-54", d:"M1333 506.5L1285 459L1313.5 427.5L1363.5 477.5L1333 506.5Z"},
        { id:"eph-55", d:"M1284.5 460.5L1254 494L1300.5 539.5L1331 508.5L1284.5 460.5Z"},
        { id:"eph-56", d:"M1253.5 496L1222 530L1268 572.5L1299.5 540L1253.5 496Z"},
        { id:"eph-57", d:"M1194.5 561V572.5L1233.5 609L1267 575L1221 532L1194.5 561Z"},
        { id:"eph-97", d:"M1685.5 865.5L1658 894.5L1711.5 945.5L1739 916L1685.5 865.5Z"},
        { id:"eph-96", d:"M1657 896L1629.5 925L1682.5 975.5L1710 946L1657 896Z"},
        { id:"eph-95", d:"M1628 926L1599.5 956L1647.5 1001.5H1659L1681.5 977.5L1628 926Z"},
        { id:"eph-83", d:"M1679 815L1625.5 871L1653 897L1713.5 833.5L1704 824.5L1701 828H1692.5L1679 815Z"},
        { id:"eph-94", d:"M1624.5 872L1570.5 928.5L1598.5 954.5L1651.5 898L1624.5 872Z"},
        { id:"eph-84", d:"M1650 787L1597 843.5L1624 869.5L1677.5 813.5L1650 787Z"},
        { id:"eph-93", d:"M1595 845L1541.5 901.5L1569.5 928L1622.5 871L1595 845Z"},
        { id:"eph-85", d:"M1621.5 760L1568 816.5L1595 842.5L1648.5 786L1621.5 760Z"},
        { id:"eph-92", d:"M1566.5 818L1513 874L1540.5 900L1593.5 844L1566.5 818Z"},
        { id:"eph-86", d:"M1592.5 733L1539.5 789L1566 815L1619.5 758.5L1592.5 733Z"},
        { id:"eph-98", d:"M1714.5 834.5L1687 863.5L1740.5 914.5L1768 885.5L1714.5 834.5Z"},
        { id:"epm-21", d:"M1847.5 967.5L1792.5 1025L1836 1063L1889 1007L1847.5 967.5Z"},
        { id:"epm-25", d:"M1791 1027L1736 1084.5L1774 1121L1835 1065L1791 1027Z"},
        { id:"epm-22", d:"M1777.5 911.5L1746.5 945L1809 1004.5L1845.5 966L1788 911.5H1777.5Z"},
        { id:"epm-23", d:"M1745 946.5L1709 984L1772.5 1044L1808 1006L1745 946.5Z"},
        { id:"epm-24", d:"M1676.5 1018.5V1028.5L1734.5 1083.5L1771 1045L1708 985.5L1676.5 1018.5Z"},
        { id:"epm-27", d:"M1765 1151.5L1757.5 1160.5L1785 1170L1770 1252.5L1835.5 1264L1820 1211L1828.5 1173L1765 1151.5Z"},
        { id:"epm-28", d:"M1755.5 1163L1692 1238.5L1768.5 1252.5L1783 1171L1755.5 1163Z"},
        { id:"epm-19", d:"M1909.5 1034L1904 1059L2015.5 1081.5L2021.5 1034L1939 1025.5L1931.5 1036L1909.5 1034Z"},
        { id:"epm-20", d:"M1890.5 1008.5L1826 1076.5L1852 1102.5L1890.5 1106L1902 1057.5L1907.5 1035.5V1024.5L1890.5 1008.5Z"},
        { id:"epm-26", d:"M1774.5 1123.5L1766 1149.5L1829 1171L1850 1104L1824.5 1078L1774.5 1123.5Z"},
        { id:"eph-46", d:"M1587.5 476.5L1533.5 533L1561 559.5L1615 503L1587.5 476.5Z"},
        { id:"eph-74", d:"M1532.5 534.5L1479 591L1507 616L1560 560.5L1532.5 534.5Z"},
        { id:"eph-47", d:"M1558.5 449L1505 505.5L1532.5 532L1586 475.5L1558.5 449Z"},
        { id:"eph-73", d:"M1503.5 507.5L1450.5 563L1477.5 589.5L1531 533.5L1503.5 507.5Z"},
        { id:"eph-48", d:"M1530 422L1476 478L1503.5 504.5L1557 448L1530 422Z"},
        { id:"eph-72", d:"M1474.5 480L1421.5 536L1449 562L1502 506L1474.5 480Z"},
        { id:"eph-49", d:"M1501 394.5L1447.5 451L1474.5 476.5L1528 420.5L1501 394.5Z"},
        { id:"eph-71", d:"M1395.5 505V511L1419.5 535L1473.5 478.5L1446 452L1395.5 505Z"},
        { id:"eph-50", d:"M1469.5 370L1417 425.5L1445.5 449.5L1499.5 393.5L1488 382L1469.5 370Z"},
        { id:"eph-79", d:"M1678.5 673.5L1625.5 730L1653.5 755.5L1706.5 699.5L1678.5 673.5Z"},
        { id:"eph-42", d:"M1703.5 590L1650 646L1678 672L1730.5 615.5L1703.5 590Z"},
        { id:"eph-41", d:"M1732.5 616.5L1679 673L1706.5 698L1760.5 642.5L1732.5 616.5Z"},
        { id:"eph-81", d:"M1740 724.5L1683.5 784.5L1691 792L1723 758.5L1768.5 801.5L1793.5 775L1740 724.5Z"},
        { id:"eph-40", d:"M1761.5 643L1708 699.5L1735.5 725.5L1789 669.5L1761.5 643Z"},
        { id:"eph-80", d:"M1707.5 701L1654 757L1682 783L1734.5 726.5L1707.5 701Z"},
        { id:"epm-11", d:"M2018 875L1968 934.5V936L2011 971L2062 931L2054 914H2042.5L2018 875Z"},
        { id:"epm-18", d:"M1985.5 953.5L1932.5 1011L1939.5 1023L2020.5 1032L2027 974.5H2010L1985.5 953.5Z"},
        { id:"epm-17", d:"M1941 915L1890 976L1921.5 1006.5L1932 1009.5L1985 952L1941 915Z"},
        { id:"epm-13", d:"M1955.5 794L1890.5 871L1924.5 899.5L1986.5 826.5L1974.5 806.5L1962.5 796.5L1955.5 794Z"},
        { id:"epm-16", d:"M1896 876.5L1846.5 935L1888 974L1939.5 913.5L1896 876.5Z"},
        { id:"epm-15", d:"M1803.5 884.5V894L1845 933.5L1894 875L1848.5 837L1803.5 884.5Z"},
        { id:"eph-99", d:"M1768 804.5L1731 844L1771 881L1807.5 842L1768 804.5Z"},
        { id:"eph-82", d:"M1723.5 761.5L1693 793.5L1709 809V819L1707 821L1729.5 842L1766 803L1723.5 761.5Z"},
        { id:"epm-14", d:"M1900.5 781L1849.5 835.5L1889 869L1953.5 792.5L1914.5 777.5L1900.5 781Z"},
        { id:"epm-12", d:"M1987.5 827.5L1926 901.5L1965 934L2015.5 873.5L1987.5 827.5Z"},
        { id:"epc-04", d:"M1094.5 273L1050 330L1167.5 434L1216 379.5L1094.5 273Z"},
        { id:"epc-03", d:"M1010 380V397L1113.5 494.5L1166 435.5L1048 331L1010 380Z"},
        { id:"epc-05", d:"M1095 272L1095.75 271.053M1095.75 271.053L1122 238L1142.5 216.5L1265 324.5L1217.5 378L1095.75 271.053Z"},
        { id:"epc-06", d:"M1164 192L1144 214.5L1266 322.5L1317.5 285.5L1204 164L1164 192Z"},
        { id:"eph-01", d:"M1155.5 451.5L1129.5 480.5L1186.5 530.5L1212.5 501.5L1155.5 451.5Z"},
        { id:"eph-03", d:"M1183.5 418.5L1183.54 418.459M1183.54 418.459L1209.5 389.5L1266 440L1240.5 469L1183.54 418.459Z"},
        { id:"eph-02", d:"M1182.5 421L1156.5 450L1213.5 500L1239 471L1182.5 421Z"},
        { id:"eph-09", d:"M1422 218.5L1375.5 248.5L1411.5 316L1420 309.5L1411.5 293.5V290.5L1447.5 235.5L1422 218.5Z"},
        { id:"eph-08", d:"M1374 248.5L1338 274L1381.5 336L1410 316L1374 248.5Z"},
        { id:"eph-07", d:"M1336.5 276L1304.5 298.5L1348 360L1380 337.5L1336.5 276Z"},
        { id:"eph-06", d:"M1303 299.5L1267.5 324.5L1264 328L1321 379L1346.5 361.5L1303 299.5Z"},
        { id:"eph-05", d:"M1263 329.5L1237.5 358.5L1294 409L1319.5 380L1263 329.5Z"},
        { id:"eph-04", d:"M1236 359L1210 388L1267 438.5L1293 409.5L1236 359Z"},
        { id:"eph-14", d:"M1598.5 279.5L1585 298L1631.5 342L1660 309.5L1604.5 257.5L1594 269L1598.5 272V279.5Z"},
        { id:"eph-13", d:"M1567 222.5L1518 279L1554 302.5L1581 266L1588 265.5L1592 268.5L1603.5 257L1567 222.5Z"},
        { id:"epc-10", d:"M1525.5 113.5L1471 248L1516.5 277.5L1565 221.5L1572 206H1598.5L1596.5 141.5L1525.5 113.5Z"},
        { id:"epc-09", d:"M1399.5 102.5L1423 216V217L1469 246.5L1523 113L1475 93L1399.5 102.5Z"},
        { id:"epc-08", d:"M1397.5 102L1275 116.5L1375 246.5L1420.5 217L1397.5 102Z"},
        { id:"eph-12", d:"M1553 304.5L1517 281L1516.5 280.5L1478 346L1501.5 361.5L1511.5 360.5L1553 304.5Z"},
        { id:"eph-11", d:"M1484.5 259.5L1442.5 322.5L1477 345L1515 280L1484.5 259.5Z"},
        { id:"eph-10", d:"M1482.5 258.5L1449.5 236.5L1413.5 291.5L1421.5 308.5L1441 322L1482.5 258.5Z"},
        { id:"eph-15", d:"M1584.5 299L1555.5 339L1599 380.5L1630.5 343.5L1584.5 299Z"},
        { id:"eph-26", d:"M1832 441.5L1817.5 457.5L1829.5 469V475.5L1821.5 483.5L1858.5 518.5L1893.5 476.5L1832 441.5Z"},
        { id:"eph-32", d:"M1872 534L1843.5 564L1894.5 613L1923.5 583L1872 534Z"},
        { id:"eph-31", d:"M1843.5 566.5L1814.5 596.5L1865.5 645.5L1895 615L1843.5 566.5Z"},
        { id:"eph-30", d:"M1813 598L1784 629L1829.5 672.5H1841.5L1865 647.5L1813 598Z"},
        { id:"eph-27", d:"M1820.5 484L1791.5 514.5L1843 563L1871.5 532.5L1820.5 484Z"},
        { id:"eph-28", d:"M1790.5 516.5L1761.5 546.5L1813.5 595.5L1841.5 565.5L1790.5 516.5Z"},
        { id:"eph-29", d:"M1760.5 547.5L1736.5 572.5V583.5L1782.5 627L1812 596.5L1760.5 547.5Z"},
        { id:"epm-09", d:"M2080.5 771.5L2038.5 865.5L2046 877L2057 851.5L2118 855.5L2122.5 790L2080.5 771.5Z"},
        { id:"epm-10", d:"M2058.5 853.5L2047 879.5L2061.5 901.5L2054.5 910L2064 931L2111.5 932.5L2117.5 857.5L2058.5 853.5Z"},
        { id:"epm-08", d:"M2036 753L2003.5 788L2002.5 808L2037.5 865L2078 771.5L2036 753Z"},
        { id:"eph-25", d:"M1770.5 415.5L1740.5 447.5L1784.5 489.5L1830.5 441L1806 427L1799 442.5L1770.5 415.5Z"},
        { id:"eph-24", d:"M1739 449L1706 484L1750 526L1783 491L1739 449Z"},
        { id:"eph-23", d:"M1704.5 485.5L1670.5 521.5L1708.5 557.5H1721L1748.5 527.5L1704.5 485.5Z"},
        { id:"eph-20", d:"M1710.5 386.5L1694.5 403.5L1739 445.5L1769.5 413.5L1717 363.5L1706 375L1710.5 379V386.5Z"},
        { id:"eph-22", d:"M1630.5 471.5V482.5L1669 519L1703.5 483L1659 441L1630.5 471.5Z"},
        { id:"eph-19", d:"M1661.5 310.5L1632.5 343.5L1678.5 387L1694.5 371H1702L1704.5 374L1716 362.5L1661.5 310.5Z"},
        { id:"eph-18", d:"M1631.5 345.5L1601 382.5L1645 424L1678 389.5L1631.5 345.5Z"},
        { id:"eph-17", d:"M1599.5 384L1566.5 423.5L1602.5 457.5H1613.5L1643.5 425.5L1599.5 384Z"},
        { id:"eph-16", d:"M1554.5 340C1545.83 351.667 1528.5 375.1 1528.5 375.5L1527.5 386L1565.5 421.5L1598.5 382.5L1554.5 340Z"},
        { id:"eph-34", d:"M1929.5 496.5L1903 544L1902.5 544.5L1933.5 574.5L1964.5 542L1975 523L1929.5 496.5Z"},
        { id:"eph-33", d:"M1895 477.5L1860 520L1925.5 582.5L1933 575.5L1900.5 544.5L1927.5 496L1895 477.5Z"},
        { id:"eph-38", d:"M1915.5 626L1884.5 658.5L1932.5 704.5L1963.5 671.5L1915.5 626Z"},
        { id:"eph-37", d:"M1947.5 591.5L1916.5 624.5L1964.5 670.5L1996 638L1947.5 591.5Z"},
        { id:"eph-36", d:"M1980 558L1949 591L1996.5 636L2027.5 603.5L1980 558Z"},
        { id:"eph-35", d:"M1976.5 523.5L1965.5 542.5L2028.5 602.5L2058.5 571L1976.5 523.5Z"},
        { id:"eph-39", d:"M1883.5 660.5L1857.5 687.5V698.5L1900 738.5L1931.5 705.5L1883.5 660.5Z"},
        { id:"epm-07", d:"M2067.5 719.5L2037.5 751.5L2123.5 789.5L2148.5 795.5L2163.5 763L2067.5 719.5Z"},
        { id:"epm-04", d:"M2060.5 571.5L2024.5 609.5L2085.5 667.5L2122 629L2087 607L2100 594L2060.5 571.5Z"},
        { id:"epm-03", d:"M2023.5 611.5L1986.5 650.5L2047.5 708.5L2084.5 669.5L2023.5 611.5Z"},
        { id:"epm-02", d:"M1985.5 651.5L1948.5 690.5L2009.5 748.5L2046.5 710L1985.5 651.5Z"},
        { id:"epm-01", d:"M1947 691L1901.5 739.5L1901 740.5L1911.5 750L1975 774L1988.5 770.5L2008.5 749.5L1947 691Z"},
        { id:"epm-05", d:"M2124.5 630.5L2105.5 649.5L2117.5 660.5V668L2108.5 677.5L2182.5 724L2205 681L2124.5 630.5Z"},
        { id:"epm-06", d:"M2106.5 678.5L2068.5 718.5L2162.5 762.5L2180.5 725.5L2106.5 678.5Z"},
        { id:"epc-07", d:"M1271.5 116.5L1206 163L1320 284.5L1372 248L1271.5 116.5Z"},
        { id:"eph-78", d:"M1650 647.5L1597 703L1624.5 728.5L1677 673L1650 647.5Z"},
        { id:"eph-44", d:"M1648 533.5L1594 590.5L1621.5 616.5L1675.5 560L1648 533.5Z"},
        { id:"eph-76", d:"M1592 591.5L1538.5 648L1566.5 674L1620 618L1592 591.5Z"},
        { id:"eph-77", d:"M1621 619.5L1568 675.5L1595 701.5L1648.5 645.5L1621 619.5Z"},
        { id:"eph-43", d:"M1676 561.5L1622.5 617.5L1649.5 644L1703.5 587.5L1676 561.5Z"},
        { id:"eph-45", d:"M1616.5 503.5L1562.5 560.5L1590.5 586.5L1644 530L1616.5 503.5Z"},
        { id:"eph-75", d:"M1561.5 561.5L1508 618L1535 644.5L1589 588L1561.5 561.5Z"},
        { id:"eph-70", d:"M1415 427L1354.5 488L1365 499L1381.5 490L1394 503L1444 451.5L1415 427Z"},
        { id:"eph-51", d:"M1417.5 343.5L1382.5 368.5L1427.5 411L1467.5 368.5L1429 343.5H1417.5Z"},
        { id:"eph-52", d:"M1381 369.5L1345 394.5L1395 444.5L1426 412.5L1381 369.5Z"},
        { id:"eph-53", d:"M1337.5 399.5L1314.5 425.5L1364.5 475.5L1393.5 446.5L1343.5 397L1337.5 399.5Z"},
        { id:"eph-118", d:"M1796 1259.5L1786.5 1317H1806L1809.5 1321.5L1811 1336L1837.5 1335L1835 1267L1796 1259.5Z"},
        { id:"eph-117", d:"M1750 1251.5L1738 1321L1783.5 1317L1794.5 1259.5L1750 1251.5Z"},
        { id:"eph-116", d:"M1709.5 1244L1695 1325L1735.5 1320.5L1747 1250.5L1709.5 1244Z"},
        { id:"eph-319", d:"M1597.5 2369L1539 2385L1549.5 2431L1609 2416.5L1597.5 2369Z"},
        { id:"eph-328", d:"M1392.5 2697.5L1348.5 2709.5H1347.5V2770L1391 2771L1392.5 2697.5Z"},
        { id:"eph-327", d:"M1309 2714.5L1289.5 2720.5L1291.5 2769.5H1345.5V2710.5L1315 2718.5L1309 2714.5Z"},
        { id:"eph-373", d:"M1287 2720.5L1222.5 2726.5V2769.5H1289.5L1287 2720.5Z"},
        { id:"eph-331", d:"M1449.5 2772.5H1411.5L1410.5 2848.5L1444.5 2849L1452 2842L1449.5 2772.5Z"},
        { id:"eph-332", d:"M1410.5 2772.5H1372.5L1371 2848.5H1410.5V2772.5Z"},
        { id:"eph-333", d:"M1370.5 2772H1331.5V2849H1369C1369.17 2823.33 1369.7 2772 1370.5 2772Z"},
        { id:"eph-334", d:"M1329.5 2771.5H1291.5V2860H1306V2853L1310.5 2848.5H1329.5V2771.5Z"},
        { id:"eph-372", d:"M1289.5 2771.5H1222.5V2815.5H1289.5V2771.5Z"},
        { id:"eph-371", d:"M1289.5 2817.5H1222.5V2861.5H1289.5V2817.5Z"},
        { id:"eph-338", d:"M1445.5 2871.5H1413.5V2946.5H1455L1454 2879.5L1445.5 2871.5Z"},
        { id:"eph-337", d:"M1411.5 2871.5H1372.5V2946.5H1411.5V2871.5Z"},
        { id:"eph-21", d:"M1693.5 405L1660 440L1704 481.5L1737.5 446.5L1693.5 405Z"},
        { id:"epc-02", d:"M969.5 432L922 492.5L1017.5 582.5L1077.5 518.5L984.5 430.5L969.5 432Z"},
        { id:"epc-01", d:"M920 494L863 567.5L953 651L1015.5 584.5L920 494Z"},
        /* { id:"Vector 3458", d:"M1484 42L2288.5 172.5V1M2288.5 1H0.5V3448H2288.5V1Z"}, */
        { id:"eph-59", d:"M1317.5 582L1265.5 637.5L1292 663L1343.5 607.5L1317.5 582Z"},
      ];

    const navigation = (data) => {
        history.push({
          pathname: `/Client-Details/${data?._id}`,
          state: { data: data },
        });
    }
  
    return (
        <>

       {/* <div className="wrapper"> */}
         {/* <div className="scrollable-content"> */}
         <div className="mapContent">
            
         <div class="d-grid gap-2 d-md-block">
            <button class="btn btn-outline-success btn-sm" onClick={()=> setIsZoom(true)}  type="button">Zoom In</button>
            <span className="mx-2"></span>
            <button class="btn btn-outline-success btn-sm" onClick={()=> setIsZoom(false)} type="button">Zoom Out</button>
         </div>

            <div  style={{background: `url(${ParadiseImage})`, backgroundSize:"cover", width:"100%", 
                ...(isZoom && {
                transform: "scale(1.5) translateX(-30%)",
                transformOrigin: "top left",
                }) }}  className="layout">
                            
                    <svg width="2289" height="3449" viewBox="0 0 2289 3449" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="ENTERO_Paradise_">
                        {svgPaths.map((path) => (
                            <path
                                key={path.id}
                                fill='rgba(255, 255, 255, 0.2)'
                                id={path.id}
                                d={path.d}
                                stroke="black"
                                onClick={() => getPlotData(path.id)}
                                />
                        ))}
                        </g>
                        
                    </svg>
            </div>
         </div>
        {/* </div> 

        </div> */}


                            <Offcanvas show={showOffcanvas} onHide={offCanvasClose} placement={'end'}>
                                  {/* <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>Edit Document</Offcanvas.Title>
                                  </Offcanvas.Header> */}
                    <Offcanvas.Body>

                            <div className="estateDetails">
                                <span>{estate.name}</span>
                                <span>{estate.location}</span>
                            </div>


                  <div className='userProfDisplay'>

                        <div className="profInfo">
                                    <h4>Property Details</h4>

                                    <div className="profInfoData">

                                        <div className="profData">
                                            <span>Name</span>
                                            <span>{estate.name}</span>
                                        </div>

                                        
                                        <div className="profData">
                                            <span>Plot Number</span>
                                            <span>{data.plotNumber}</span>
                                        </div>

                                        <div className="profData">
                                            <span>Sqm</span>
                                            <span>{data.size}m<sup>2</sup></span>
                                        </div>

                                        <div className="profData">
                                            <span>Status</span>
                                            <span className={data.status === 'sold' ? 'soldStatus' : (data.status === 'pending' ? 'pendingStatus' : 'availableStatus')}>{data.status === 'sold' ? 'sold' : (data.status === 'reserved' ? 'reserved' : 'available')}</span>
                                        </div>
                                    </div>
                                </div>

                        <div className="profInfo">
                            {data.docId ? <>

                                <h4>Owned By</h4>

                                <div className="user-details">

                                    <Image crossorigin="anonymous" src={`${configData.TEXT_IMG}/${data.docId.clientId.passport}`} className="useDataImg3" alt="" />
                                    <div className="userDataName">
                                        <span>{data.docId.clientId.fullName}</span>
                                        <span>{data.docId.clientId.phoneNumber}</span>
                                    </div>
                                </div>

                                <div className="profInfoData">

                                    <div className="profData">
                                        <span>Full Name</span>
                                        <span>{data.docId.clientId.fullName}</span>
                                    </div>

                                    <div className="profData">
                                        <span>Phone Number</span>
                                        <span>{data.docId.clientId.phoneNumber}</span>
                                    </div>
                                    
                                    <div className="profData">
                                        <span>Sex</span>
                                        <span>{data.docId.clientId.sex}</span>
                                    </div>
                                    
                                    <div className="profData">
                                        <span>Email</span>
                                        <span>{data.docId.clientId.email}</span>
                                    </div>

                                    <div className="view-more" onClick={() => navigation(data.docId.clientId)}>
                                        <span>view more</span>
                                    </div>

                                    
                                </div>

                                </> : <> 
                                <span>This property does not have an owner</span> 
                            </>}
                        </div>

                    </div> 

                                  </Offcanvas.Body>
                              </Offcanvas>





            
        </>
    );
};

export default Paradise;